import React from 'react';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Head from './app_bar';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import Paper from '@material-ui/core/Paper';


import '../css/mapbox_demo.css';
import '../css/mapbox-gl.css';

const theme = createMuiTheme({
	palette: {
	  primary: {
		main: '#F3F3F3',
	  },
	},
});

export interface State {
	selected_layer: any
}

class Micro_Mobility extends React.Component<State> {
	public state:State = {
		selected_layer: "mobility" // start or end
	}

    
	private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            selected_layer: (event.target as HTMLInputElement).value,
        });
    }

  	render() {

    return (
        
        <div>
                <ThemeProvider theme = {theme}>
                    <Box bgcolor="primary.main" color="primary.contrastText" className="box"></Box>
                </ThemeProvider>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Head containSearch={true}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0} alignItems='stretch' alignContent='center'>
                            <Grid item xs={3} className='left_column' spacing={0}>
							<Paper className='layer_control'>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Choose one layer to display:</FormLabel>
                                    <RadioGroup aria-label="layers" name="layers1" value={this.state.selected_layer} onChange={this.handleChange}>
                                    <FormControlLabel value={"mobility"} control={<Radio color='primary'/>}  label={<div>mobility coverage</div>} />
                                    <FormControlLabel value={"start"} control={<Radio color='primary'/>}  label={<div>Trips start near stations</div>} />
                                    <FormControlLabel value={"end"} control={<Radio color='primary'/>}  label={<div>Trips end near stations</div>} />
                                    </RadioGroup>
                                </FormControl>
                                </Paper>
                            </Grid>
                            <Grid item xs={9} className='left_column' spacing={0} >
                                {this.state.selected_layer == "mobility" && <iframe src="http://okn.eecs.umich.edu:5000/sharedModeCovergaeV2-okn.html" className="mapContainerMM"/>}
								{this.state.selected_layer == "start" && <iframe src="http://okn.eecs.umich.edu:5000/startPointsNearTransitFullData.html" className="mapContainerMM"/>}
								{this.state.selected_layer == "end" && <iframe src="http://okn.eecs.umich.edu:5000/endPointsNearTransitFullData.html" className="mapContainerMM"/>}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
    );
  }
}

export default Micro_Mobility;