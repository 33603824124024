import React from 'react';

import { createMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Head from './app_bar';

import '../css/mapbox-gl.css';
import '../css/use_case_detail.css';

export interface State {
    selected_layer: any
    // popupInfo: any
}

class Usecase_Details extends React.Component<State> {
    public state:State = {
        selected_layer: "start" // start or end
    }


    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            selected_layer: (event.target as HTMLInputElement).value,
        });
    }

    render() {

        return (

            <div>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Head containSearch={true}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0}>
                            <Grid item xs={8}>
                                <div className="container">
                                    <p className="head">Detroit Shared Micromobility Pilot and Regulation</p>
                                    <table className="usecaseDetailTable">
                                        <tr>
                                            <td>
                                                <div>
                                                    <p className="tableSubHeader">Research Conducted by:</p>
                                                    <p className="tableText">Eric Hanss, Andong Chen, Spencer Vagg</p>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <p className="tableSubHeader">Source Data:</p>
                                                    <p className="tableText">Dataset</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div>
                                                    <p className="tableSubHeader">Outcomes Reported by:</p>
                                                    <p className="tableText">Eric Hanss, Andong Chen, Spencer Vagg</p>
                                                </div>
                                            </td>
                                            <td>
                                                <div>
                                                    <p className="tableSubHeader">Map View:</p>
                                                    <a href="micro_mobility" target="_blank" className="tableText">OKN Micromobility Map</a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div>
                                                    <p className="tableSubHeader">Options</p>
                                                    <div>
                                                        <div className="chip">
                                                            <p className="chipText">Save as PDF</p>
                                                        </div>
                                                        <div className="chip">
                                                            <p className="chipText">Print</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                    <p className="textSubTitle">Background</p>
                                    <p className="text">Shared micromobility—human-or electric-powered low speed, single-occupancy vehicles such as bikes or scooters that can be rented for short durations from the public right of way through a mobile app or kiosk<sup>1</sup> —rose to prominence with the disruptive launch of dockless scooter and bike fleets in 2017. Publicly-supported bikeshare systems, however, have been operating for years in many US cities. The addition of scooters to the shared micromobility ecosystem has led to rapid growth in annual net trips, which topped 84 million in 2018, more than double that of 2017<sup>2</sup>. </p>
                                    <p className="text">For-hire, dockless scooters launched in Detroit in July of 2018. Like ridesourcing services before them, dockless scooter companies introduced their initial fleets in multiple cities in rapid succession, in some instances outpacing local or state regulations. In response, municipalities raced to enact legislation or contract with scooter companies to harness the potential benefits of this novel technology—reduction of automobile emissions, first and last mile connections to transit—while mitigating the downsides—crashes, blocked sidewalks and curb ramps. </p>
                                    <p className="text">The City of Detroit issued a <i>Memorandum of Interpretation</i><sup>3</sup> shortly before the first scooters hit the streets to guide operators and riders in the proper deployment and use of these devices. To respond in a timely manner and to allow future regulatory flexibility, Detroit strategically opted for controls that had the effect of law instead of contractual terms or legislation with the force of law. Rather than attempt to anticipate how Detroiters would use scooters and how companies would deploy and maintain their fleets in a quickly changing industry, the City elected to regulate based on local experience. The resulting iterative approach, led by the Office of Mobility Innovation (OMI), has resulted in revisions to the initial memo and a dynamic pricing pilot with mobility-focused data firm Passport Labs<sup>4</sup>. </p>
                                    <p className="text">Detroit is positioned to draw from its own base of experience with scooter operations as well as
                                        those of peer cities to craft innovative regulations that establish clear, enforceable safety and
                                        business standards for scooters and future forms of micromobility while promoting sustainable transportation options for all of Detroit’s residents.</p>
                                    <p className="text">The Transportation Equity Open Knowledge Network at the University of Michigan (TEOKN)
                                        provided policy and data analytics support in early 2020 to advance the City’s goal of creating a micromobility regulatory model that can be scaled nationally.</p>
                                    <p className="textSubTitle">Regulatory Approach</p>
                                    <p className="text">Scooters constitute the most recent wave of shared micromobility in Detroit, but are unlikely to
                                        be the last in an industry defined by disruption and constant experimentation. Since the
                                        appearance of dockless bikes and scooters in US cities in 2017 and 2018, platforms and
                                        business models have entered and exited the marketplace at a high rate of churn as companies
                                        search for a path to profitability. Traditional regulatory approaches, legislated through one-time
                                        ordinances or long-term contracts, may not be compatible with an environment defined by
                                        rapid adaptation and innovation. To that end, TEOKN recommended five principles for micromobility regulation in Detroit:</p>
                                    <p className="text"><b>Flexibility:</b> future regulation should enable the City to experiment and adjust penalties, incentives, fleet sizes, and geographic distributions.</p>
                                    <p className="text"><b>Financial Sustainability:</b> the City should set reasonable charges and fees that enable micromobility companies that can show net public benefit to operate a viable business in Detroit.</p>
                                    <p className="text"><b>Accountability:</b> clear police powers are necessary to ensure accountability from micromobility companies and safe behavior from users. The City must also model accountability by leveraging data in support of enforcement mechanisms to identify and address issues.</p>
                                    <p className="text"><b>Consistency:</b> micromobility companies operating in Detroit should expect equal treatment in regards to operations and oversight as well as expectations on-par with those of peer cities. The City should exercise parity between micromobility companies and other forms of mobility, commensurate with public impact.</p>
                                    <p className="text"><b>Equity:</b> Detroit’s existing transportation system, culture, demography, and land use demand special attention to ensure program sustainability and access for all Detroiters.</p>
                                    <p className="text">TEOKN and OMI also reviewed and incorporated regulations from peer cities including Atlanta, Cleveland, Oakland, Portland, and Minneapolis alongside guidance from the most recent version of the <i>Guidelines for Regulating Shared Micromobility</i> issued by the National Association of City Transportation Officials<sup>5</sup>. </p>
                                    <p className="text">The resulting regulatory structure for micromobility in Detroit is composed of three parts: a City ordinance that establishes the City’s legal authority to permit, regulate, and enforce shared micromobility use, an updatable resolution to set fees for companies operating in the public right-of-way, and a common short-term operation agreement between the City and each private company selected to provide for-hire shared micromobility services within Detroit. </p>
                                    <p className="text">Separating the regulation into three components enables the City to better respond to resident feedback and staff recommendation without undue burden to City Council.</p>
                                    <p className="text">Through the ordinance, City Council sets the high-level policy expectations that micromobility companies must adhere in order to operate in Detroit--safe operations, staffing, equitable distribution, access for low-income and unbanked residents, data sharing—and also provides necessary leeway for City staff to test and implement policy designs like geofencing and incentives through the operation agreement to keep the program flexible and sustainable. </p>
                                    <p className="textSubTitle">Policy Design Use Cases</p>
                                    <p className="text">TEOKN assisted the City in analyzing anonymized scooter trip data to inform the design of specific micromobility policies within future operating agreements.</p>
                                    <p className="text">Mobility dashboards provided by the scooter companies or available through third party providers put real-time data at the fingertips of public scooter managers to assess daily usage and respond to citizen complaints, to name a few applications. These platforms struggle, however, with historical analyses and cannot easily interact scooter trips with the spatial and temporal data sets that policy makers need to make more complex decisions. The TEOKN data portal is an ideal platform for analyzing scooter trips against additional data sets and developing policy-relevant data visualizations and tools for non-technical users.</p>
                                    <p className="text">The City of Detroit shared discrete scooter trip data from two private providers encompassing trip start and end locations and times between March 1, 2019 and September 30, 2019 with TEOKN, further supplementing those data with one month of scooter travel paths from one provider. No personally identifiable information, such as names or payment details, were contained in any data shared between the City and TEOKN.</p>
                                    <p className="text"><u>Where are Scooter Users Going?</u></p>
                                    <p className="text">Effective shared scooter policy starts in understanding the potential applications and limitations of the platform. How far is an average scooter trip? What parts of the city are scooter users accessing? Answers to these questions can shine a light on the types of trips that Detroiters and visitors make on scooters.</p>
                                    <p className="text">
                                        Based on breadcrumb data, scooter trips in Detroit tended to be very short, with over 75% at under a mile in length and a median distance of half of a mile. Deployment and land use constrained the distribution of scooter trips. The high density of trips in the core of the city (Figure 1) and the short trip distances, therefore, do not come as a surprise. Scooter riders also took trips along the Detroit Riverwalk and accessed neighborhoods near Downtown and Midtown including New Center, Corktown, and West Village. Some users took longer trips or rode scooters in neighborhoods such as East English Village on the city’s northeast side and the Livernois-Six Mile corridor in the north-central area.
                                    </p>
                                    <p className="imageCaptionText">Figure 1: Number of Scooter Trips by Block, October 2019</p>
                                    <img className="figureimg" src="images/dmm-casestudy-fig1.png"></img>
                                    <p className="text">Without aggregation to a common unit like the street segment, the daily snapshot of trip trajectories and scooters waiting to be rented available through dashboards may suggest a more uniform usage pattern throughout the city, leading to different and misleading policy choices.</p>
                                    <p className="text">These findings and visualizations begin to tell a story about how Detroiters use scooters and where scooters might diverge from other shared modes in policy-relevant ways. Land use and destinations matter, but so does the initial vehicle deployment pattern. Since trips are short, many scooters won’t make their way out into the neighborhoods where non-auto alternatives are needed without broader deployment outside of the city’s core.</p>
                                    <p className="text"><u>Where Should Scooters be Deployed?</u></p>
                                    <p className="text">Scooter companies will not deploy scooters in areas where they will sit idle without public incentives to offset losses. As a result, certain areas of the city and communities in need of convenient, affordable micromobility options may go underserved. Cities can either structure fees and permit terms so that companies meet equitable distribution requirements or they can demonstrate untapped demand outside of the high density areas that the companies compete fiercely over.</p>
                                    <p className="text">To assist Detroit in defining potential equity deployment zones and assessing potential micromobility demand, TEOKN built an interactive shared mode availability tool highlighting areas with high and low availability of shared modes including transit, carshare, bike share, and scooter trip starts at the Census tract level (see Figure 2).</p>
                                    <p className="imageCaptionText">Figure 2: Shared Mode Availability Tool Overview</p>
                                    <img className="figureimg" src="images/dmm-casestudy-fig2.png"></img>
                                    <p className="text">Beyond current shared mode coverage, program managers and decision makers can bring up key Census details like percentage of households without cars, unemployment rate, mobile broadband availability, working age population, and number of jobs, to define areas of need and opportunity (see Figure 3).</p>
                                    <p className="imageCaptionText">Figure 3: Shared Mode Availability Tool, Interactive Detail</p>
                                    <img className="figureimg" src="images/dmm-casestudy-fig3.png"></img>
                                    <p className="text"><u>Where are Scooters Users connecting to Transit?</u></p>
                                    <p className="text">Short scooter trips are improbable substitutes for bus and light rail rides. They may, however, complement transit service by unlocking first- and last-mile connections between riders’ residences and destinations beyond the typical quarter mile walk shed. </p>
                                    <p className="text">Using buffer analysis, TEOKN identified scooter trip start and stop points near to DDOT bus stops, defining a potential universe of last-mile and first-mile trips, respectively (see Figure 4). Along with land use, job density, and data measuring connectivity to other shared modes, these results can be incorporated into planning for future mobility hubs.</p>
                                    <p className="text">Furthermore, first- and last- mile connections to transit outside of the central business district suggest latent demand for scooters outside the historical deployment area, suggesting that scooter companies may find a pool of riders in new areas, pending proper deployment, marketing, and fare options.</p>
                                    <p className="imageCaptionText">Figure 4: Scooter Trip End Points within 50’ of DDOT Stops, Mar 1 - Sept 30, 2019</p>
                                    <p className="text"><u>Where and When Should the City Geofence?</u></p>
                                    <p className="text">Detroit’s experience with scooter user behavior has led the City to consider throttling scooters below 15mph at peak times in areas with high potential for conflict with pedestrians. Trip time stamps and location data indicate heavy scooter utilization in Detroit’s Downtown area on weekdays between noon and 8PM, with high ridership on weekend afternoons (see Figure 5). By pairing location-specific activity curves with multi-modal travel data from Detroit’s Traffic Operations Center, Detroit’s policymakers can make informed decisions about when and where to institute geofencing to ensure safe operations.</p>
                                    <p className="imageCaptionText">Figure 5: Scooter Activity Curves in Downtown Detroit, Mar 1 - Sept 30, 2019</p>
                                    <img className="figureimg" src="images/dmm-casestudy-fig5.png"></img>
                                    <p className="textSubTitle">CONCLUSION</p>
                                    <p className="text">TEOKN’s collaboration with Detroit’s Office of Mobility Innovation serves as proof of concept that combining historical anonymized scooter trip data available to most local governments with open data sets can unlock a wealth of new insights to unlock the benefits of micromobility. Even simple analytical procedures can put policy-relevant, value-added data at the fingertips of program managers and decision makers. Many of the use cases developed in Detroit--geofencing, mobility hubs, and equity-based fleet deployments--are common to cities across the US. Cities looking to replicate this case study can find ready-built visualizations through the TEOKN portal in addition to a wealth of mobility data and demographic data sets focused on advancing transportation equity.</p>

                                    <hr />
                                    <p className="tableText">
                                        <ol>
                                            <li>Many organizations have slightly different definitions for micromobility, complicated by user-owned micromobility and shared options and intersections with active transportation. NACTO’s term “shared micromobility” is used here because it best corresponds to how micromobility is widely used today.</li>
                                            <li><a href="https://nacto.org/wp-content/uploads/2019/09/NACTO_Shared_Micromobility_Guidelines_Web.pdf" target="_blank">https://nacto.org/wp-content/uploads/2019/09/NACTO_Shared_Micromobility_Guidelines_Web.pdf</a></li>
                                            <li><a href="https://detroitmi.gov/sites/detroitmi.localhost/files/2018-10/Dockless%20Scooters.pdf" target="_blank">https://detroitmi.gov/sites/detroitmi.localhost/files/2018-10/Dockless%20Scooters.pdf</a></li>
                                            <li><a href="https://www.crainsdetroit.com/technology/companies-study-detroit-scooter-use-patterns-could-lead-dynamic-pricing" target="_blank">https://www.crainsdetroit.com/technology/companies-study-detroit-scooter-use-patterns-could-lead-dynamic-pricing</a></li>
                                            <li><a href="https://nacto.org/sharedmicromobilityguidelines/" target="_blank">https://nacto.org/sharedmicromobilityguidelines/</a></li>
                                        </ol>
                                    </p>

                                </div>

                            </Grid>
                            <Grid item xs={4}>
                                <div className="imageContainer">
                                    <img src="images/black-kick-scooters-parked-near-body-of-water-2547627.jpg" className="image"/>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default Usecase_Details;