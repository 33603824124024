import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {RouteComponentProps} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { deepPurple } from '@material-ui/core/colors';
import Head from './app_bar';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        flexGrow: 1,
    },
    head: {
        height: '10vh',
    },
    left: {
        height: '80vh',
        padding: theme.spacing(5),
        wordWrap: 'break-word',
        overflow: 'auto',
        backgroundColor: '#F3F3F3',
        marginTop: theme.spacing(3),
    },
    right: {
        height: '89vh',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingTop:theme.spacing(0),
        paddingBottom: theme.spacing(0),
        overflow: 'auto',
        marginTop: theme.spacing(3),
    },
    // right_bottom: {
    //     marginTop: theme.spacing(1)
    // },
    control: {
        height: '4vh',
        fontSize: '32px',
        paddingLeft: theme.spacing(5),
        paddingBottom: theme.spacing(0)
    },
    purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },
    col_name: {
        color: deepPurple[500],
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        wordWrap: "break-word"
    },    
    col_description: {
        padding: theme.spacing(2),
    },    
    col_type: {
        padding: theme.spacing(2),
        paddingRight: theme.spacing(3),
        wordWrap: "break-word"
    },
    table: {
        width: "100%",
        height:"40vh",
        overflow:"auto",
    },
    schema: {
        height: "40vh",
        width: "100%",
        overflow: 'auto'
    },
  }),
);

function Table_details(props: {data: any, meta: any, error_code: number}) {
  
    const classes = useStyles();
    const meta = props.meta;
    const data = props.data;
    const error_code = props.error_code;


    return (
        <Grid container className={classes.root} spacing={0}>
            <Grid item xs={12}>
                <Head containSearch={true} />
            </Grid>
            <Grid item xs={12}>
                <Grid container justify='center' spacing={0}>
                    <Grid item xs={3}>
                        <Paper className={classes.left} elevation={0} square>
                            <Typography variant="h5" gutterBottom>
                                {meta.tbl_name}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                {meta.tbl_desc}
                            </Typography>
                            <br/>
                            <Typography variant="subtitle1" gutterBottom> 
                                Tags
                            </Typography>
                            <div>
                                {meta.tags.map((tag: any)=>{
                                    return <Chip label={tag} />;
                                })}
                            </div>
                            <br/>
                            <Typography variant="subtitle1" gutterBottom> 
                                Table Database
                            </Typography>
                            <Typography variant="body2" color="textSecondary" gutterBottom>
                                {meta.tbl_database}
                            </Typography>
                            <br/>
                            {/* <Typography variant="subtitle1" gutterBottom> 
                                Table Schema
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {meta.tbl_schema}
                            </Typography>
                            <br/> */}
                        </Paper>
                    </Grid>
                    <Grid item xs={7}>
                        <Paper className={classes.right} elevation={0} square>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle1' gutterBottom>
                                        Fields
                                    </Typography>
                                    <div className={classes.schema}>
                                        {
                                            meta.cols.map((col_info: any)=>{
                                                return (
                                                    <div>
                                                    <Paper elevation={0} variant='outlined' square>
                                                    <Grid container spacing={0} alignItems='center'>
                                                        <Grid item xs={3} className={classes.col_name}>
                                                            <p>{col_info.col_name}</p>
                                                        </Grid>
                                                        <Grid item xs={2} className={classes.col_type}>
                                                            <p>{col_info.col_type}</p>
                                                        </Grid>
                                                        <Grid item xs={7} className={classes.col_description}>
                                                            <p>{col_info.col_desc}</p>
                                                        </Grid>
                                                    </Grid>
                                                    </Paper>
                                                    <br/>
                                                    </div>                                                            
                                                );
                                            })
                                        }
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        Table Preview
                                    </Typography>
                                    { error_code === 0 &&                                    
                                    <TableContainer component={Paper} className={classes.table}>
                                        <Table stickyHeader aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    {
                                                        meta.cols.map((col_info: any)=>{
                                                        return <TableCell>{col_info.col_name}</TableCell>
                                                        })
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {data.data.map(row => {
                                                return (
                                                    <TableRow key={row}>
                                                        {
                                                            row.map((row_data: any)=>{
                                                                return<TableCell>{row_data}</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                )
                                            })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    }
                                    {error_code === 1 && <p>Table not loaded.</p>}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    );
}

type Detail_params = {
    tbl_db: string,
    tbl_schema: string,
    tbl_name: string
}

class Table_Detail_Page extends React.Component<RouteComponentProps<Detail_params>>{

    public state = {
        loading_meta: true,
        loading_data: true,
        meta_data: {},
        data: {},
        error_code: 0, //0: no error, 1: not loaded, 2: not found, 3: unknown
    }

    public componentDidMount(): void {
        this.setState({
            loading: true
        });
        // let baseUrl = 'http://localhost:4000/file/data.json';
        let baseUrl = 'http://okn.eecs.umich.edu:5000/';
        const uri = this.props.match.params[0];
        let table_url = baseUrl + 'table/' + uri;
        let table_data_url = baseUrl + 'table_data/' + uri;
        fetch(table_url)
        .then(response => response.json())
        .then((jsonData) => {
            
            this.setState({
                loading_meta: false,
                meta_data: jsonData
            })
        })
        .catch((error) => {
            console.error(error)
        });
        fetch(table_data_url)
        .then(response => response.json())
        .then((jsonData) => {
            if ("error" in jsonData){
                this.setState({error_code: 1})
            }
            console.log(jsonData);
            this.setState({
                loading_data: false,
                data: jsonData
            })
        })
        .catch((error) => {
            console.error(error)
        });
    }
    public render() : React.ReactNode{
        if (this.state.loading_meta || this.state.loading_data){
            return <div/>
        }
        else{
            console.log(this.state.error_code);
            return <Table_details data = {this.state.data} meta = {this.state.meta_data} error_code = {this.state.error_code}/>
        }        
    }
}

  
export default Table_Detail_Page;