import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import TalentGap from './talent_gap';

type Usecase_params = {
    usecase_name: string,
}

class Use_Case extends React.Component<RouteComponentProps<Usecase_params>>{

    public state = {
        loading: true,
        data: {},
        usecase_name: ''
    }

    public componentDidMount(): void {
        this.setState({
            loading: true
        });
        // let baseUrl = 'http://localhost:4000/file/data.json';
        let baseUrl = 'http://okn.eecs.umich.edu:5000/search/';
        const {usecase_name} = this.props.match.params;
        this.setState({usecase_name: usecase_name})
        let url = baseUrl + usecase_name;
        console.log(url);
        fetch(url)
        .then(response => response.json())
        .then((jsonData) => {
            this.setState({
                loading: false,
                data: jsonData.usecase.hits.hits[0]
            })
        })
        .catch((error) => {
            console.error(error)
        });
    }
    public render() : React.ReactNode{
        if (this.state.loading){
            return <div/>
        }
        else if (this.state.usecase_name === "talent gap"){
            return <TalentGap usecase_details = {this.state.data}/>
        }
        else{
            return <div><p>404 Not Found</p></div>
        }        
    }
}

  
export default Use_Case;