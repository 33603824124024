import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import {RouteComponentProps} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import ListItemText from '@material-ui/core/ListItemText';

import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import { deepPurple } from '@material-ui/core/colors';
import Head from './app_bar';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

// styles
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
        flexGrow: 1,
    },
    head: {
        height: '10vh',
    },
    left: {
        height: '80vh',
        padding: theme.spacing(5),
        wordWrap: 'break-word',
        overflow: 'auto',
        backgroundColor: '#F3F3F3',
        marginTop: theme.spacing(3),
    },
    right: {
        height: '89vh',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingTop:theme.spacing(0),
        paddingBottom: theme.spacing(0),
        overflow: 'auto',
        marginTop: theme.spacing(3),
    },
    control: {
        height: '4vh',
        fontSize: '32px',
        paddingLeft: theme.spacing(5),
        paddingBottom: theme.spacing(0)
    },
    purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },
    tbl_name: {
        color: deepPurple[500],
        padding: theme.spacing(2),
        wordWrap: 'break-word',
    },    
    tbl_description: {
        padding: theme.spacing(2),
        wordWrap: 'break-word',
    },    
    table: {
        width: "100%",
        height:"40vh",
        overflow:"auto",
    },
    schema: {
        height: "40vh",
        width: "100%",
        overflow: 'auto'
    },
    col_name: {
        color: deepPurple[500],
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        wordWrap: "break-word"
    },    
    col_description: {
        padding: theme.spacing(2),
    },    
    col_type: {
        padding: theme.spacing(2),
        paddingRight: theme.spacing(3),
        wordWrap: "break-word"
    },
  }),
);

function Table_List_Row (props: {table: any, idx: number}){
    const table = props.table;
    const idx = props.idx;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false)
    function handleClick(){
        setOpen(!open);
    }
    return (
        <div>
            <ListItem button onClick={handleClick}>
                <ListItemText primary={
                <div>
                        <Grid container spacing={0} alignItems='center'>
                            <Grid item xs={4} className={classes.tbl_name}>
                                <p>{table[1].tbl_name}</p>
                            </Grid>
                            <Grid item xs={6} className={classes.tbl_description}>
                                <p>{table[1].tbl_desc}</p>
                            </Grid>
                        </Grid>
                </div>
                } />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem>
                        <ListItemText primary={
                            <div>
                                {
                                    table[1].cols.map((col_info: any)=>{
                                        return (
                                            <div>
                                            <Paper elevation={0} variant='outlined' square>
                                            <Grid container spacing={0} alignItems='center'>
                                                <Grid item xs={3} className={classes.col_name}>
                                                    <p>{col_info.col_name}</p>
                                                </Grid>
                                                <Grid item xs={2} className={classes.col_type}>
                                                    <p>{col_info.col_type}</p>
                                                </Grid>
                                                <Grid item xs={7} className={classes.col_description}>
                                                    <p>{col_info.col_desc}</p>
                                                </Grid>
                                            </Grid>
                                            </Paper>
                                            <br/>
                                            </div>                                                            
                                        );
                                    })
                                }
                            </div>
                        }
                        />
                    </ListItem>
                </List>
            </Collapse>
        </div>
    )
}

function Table_details(props: {data: any, meta: any, error_code: number}) {
  
    const classes = useStyles();
    const meta = props.meta;
    const data = props.data.sort(function(a, b){return a[1].tbl_name - b[1].tbl_name});
    const baseUrl = 'http://okn.eecs.umich.edu:5000/';

    const [error_code, setErrorCode] = React.useState(props.error_code);
    const [loading, setLoading] = React.useState(true);
    const [currentTable, setCurrentTable] = React.useState();
    const [currentTableIdx, setCurrentTableIdx] = React.useState(0);

    console.log(error_code);
    React.useEffect(()=>{
        if (error_code === 0){
            let uri = data[0][0];
            fetch(baseUrl + 'table_data/' + uri)
            .then(response=>response.json())
            .then((jsonData)=>{
                if ("error" in jsonData){
                    setErrorCode(1);
                }
                setCurrentTable(jsonData);
                setLoading(false);
            })
            .catch((error)=>{
                setErrorCode(1);
                console.error(error)
            })
        }
        }, []);

    return (
        <Grid container className={classes.root} spacing={0}>
            <Grid item xs={12}>
                <Head containSearch={true} />
            </Grid>
            {error_code === 2 && <p>Table not found.</p>}
            {error_code !==2 &&
            <Grid item xs={12}>
                <Grid container justify='center' spacing={0}>
                    <Grid item xs={3}>
                        <Paper className={classes.left} elevation={0} square>
                            <Typography variant="h5" gutterBottom>
                                {meta.db_name}
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                {meta.db_desc}
                            </Typography>
                            <br/>
                            <Typography variant="subtitle1" gutterBottom> 
                                Original Database
                            </Typography>
                            <a href={meta.db_origin}>{meta.db_origin}</a>
                            <br/>
                            <Typography variant="subtitle1" gutterBottom> 
                                Uploader
                            </Typography>
                            <Typography variant="body2" gutterBottom> 
                                {meta.db_uploader}
                            </Typography>
                            <br/>
                            <Typography variant="subtitle1" gutterBottom> 
                                Tags
                            </Typography>
                            <div>
                                {meta.tags.map((tag: any)=>{
                                    return <Chip label={tag} />;
                                })}
                            </div>
                            {/* <Typography variant="subtitle1" gutterBottom> 
                                Table Schema
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                {meta.tbl_schema}
                            </Typography>
                            <br/> */}                        
                        </Paper>
                    </Grid>
                    <Grid item xs={7}>
                        <Paper className={classes.right} elevation={0} square>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle1' gutterBottom>
                                        Tables
                                    </Typography>
                                    <div className={classes.schema}>
                                    <List
                                        component="nav"
                                        aria-labelledby="nested-list-subheader"
                                    >
                                        {
                                            data.map((table: any, idx: number) => {
                                                return (
                                                    <Table_List_Row table={table} idx={idx}/>
                                                )
                                            })
                                        }
                                    </List>

                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle1" gutterBottom>
                                        {data[currentTableIdx][1].tbl_name} Preview
                                    </Typography>
                                    { !loading && error_code !== 1 && (
                                    <TableContainer component={Paper} className={classes.table}>
                                        <Table stickyHeader aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    {
                                                        data[currentTableIdx][1].cols.map((col_info: any)=>{
                                                        return <TableCell>{col_info.col_name}</TableCell>
                                                        })
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                            {currentTable.data.map(row => {
                                                return (
                                                    <TableRow key={row}>
                                                        {
                                                            row.map((row_data: any)=>{
                                                                return<TableCell>{row_data}</TableCell>
                                                            })
                                                        }
                                                    </TableRow>
                                                )
                                            })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    )}
                                    { error_code === 1 && <p>Table not loaded</p>}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>

                </Grid>
            </Grid>
            }
        </Grid>
    );

}

type Detail_params = {
    tbl_db: string,
    tbl_schema: string,
    tbl_name: string
}

// Material UI works with function component but not class components
// Here is a class wrapper of the component in order to use componentDidMount to fetch data
class Database_Detail_Page extends React.Component<RouteComponentProps<Detail_params>>{

    public state = {
        loading_meta: true,
        loading_data: true,
        error_code: 0, //0: no error, 1: not loaded, 2: not found, 3: unknown
        meta_data: {}, // db meta
        data: [], // table meta

    }

    public componentDidMount(): void {
        // let baseUrl = 'http://localhost:4000/file/data.json';
        let baseUrl = 'http://okn.eecs.umich.edu:5000/';
        const uri = this.props.match.params[0];
        let database_url = baseUrl + 'database/' + uri;
        fetch(database_url)
        .then(response => response.json())
        .then((jsonData) => {
            if (!Object.keys(jsonData).length ){
                this.setState({
                    error_code: 2,
                    loading_meta: false,
                    loading_data: false,
                })
            }
            else{
                this.setState({
                    loading_meta: false,
                    meta_data: jsonData
                })
                Promise.all(jsonData.tab_uris.map(uri => 
                    fetch(baseUrl + 'table/' + uri)                
                    .then(response => response.json())
                    .then((jsonData) => {
                        this.setState({
                            data: [...this.state.data, [uri,jsonData]]
                        });
                    })
                ))
                .then(()=>{
                    this.setState({
                        loading_data: false,
                    });


                })
            }
        })
        .catch((error) => {
            console.error(error)
        });
        
    }
    public render() : React.ReactNode{
        console.log(this.state.error_code);
        if (this.state.loading_meta || this.state.loading_data){
            return <div/>
        }
        else if (this.state.error_code===3){
            return <div><p>Unknown error</p></div>
        }
        else{
            return <Table_details data = {this.state.data} meta = {this.state.meta_data} error_code = {this.state.error_code}/>
        }        
    }
}

  
export default Database_Detail_Page;