import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Table_Detail_Page from './table_detail_page';
import Database_Detail_Page from './database_detail_page';
import Use_Case from './use_cases';
import Search_Page from './search_page';
import Results_Page from "./search_results";
import Results_Page_Old from "./search_result_old";
import Big_Map from "./bigmap";
import Projects_Page from "./project_page";
import Demo_Raj_Chetty from "./raj_chetty";
import Micro_Mobility from "./micromobility";
import Usecase_Details from "./usecase_detail_page";

export default function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <Search_Page/>
          </Route>
          <Route path="/TableDetails/*" component = {Table_Detail_Page} />
          <Route path="/DatabaseDetails/*" component = {Database_Detail_Page} />
          <Route path="/use_cases/:usecase_name" component = {Use_Case} />
          <Route path="/search_results/:key" component = {Results_Page} />
          <Route path="/search_results_old/:key" component = {Results_Page_Old} />
          <Route path="/bigmap" component = {Big_Map} />
          <Route path='/projects' component = {Projects_Page} />
          <Route path='/raj_chetty' component={Demo_Raj_Chetty}/>
          <Route path='/micro_mobility' component={Micro_Mobility}/>
          <Route path='/use_cases_details' component = {Usecase_Details}/>
        </Switch>
      </div>
    </Router>
  );
}