import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography, {TypographyProps} from '@material-ui/core/Typography';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem, {ListItemProps} from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import { fade, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Popper from '@material-ui/core/Popper';
import '../css/okn_head.css';

const theme = createMuiTheme({
	palette: {
	  primary: {
		main: '#151E43',
	  },
	},
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
      },
    },
    // searchIcon: {
    //   padding: 10
    // },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(0, 0, 1, 1),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 200,
      },
	},
	typography: {
		padding: theme.spacing(2),
	},
	popper:{
		width: '25vw',
		minWidth: '300px',
		zIndex: 2,
		maxHeight: '50vh',
		overflow: 'auto',
	},
	popperPaper:{
		width: '90%',
		margin: theme.spacing(1,0,0,0)

	},
	popperlist: {
		width: '95%',
        padding: theme.spacing(1),
		wordWrap: 'break-word',
    },
	appbar: {
		zIndex: 0,
		width: '100%'
	}
  }),
);

const homeURL = 'http://' + window.location.host + '/';

function TypographyLink(props: TypographyProps<'a', { button?: true }>) {
	return <Typography button component="a" {...props} />;
}

function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
	return <ListItem button component="a" {...props} />;
  }

type AppBar_params = {
    containSearch: boolean,
}

export default function Head(props: AppBar_params) {
	const classes = useStyles();
  	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState(<div/>);
	const [searchKey, setSearchKey] = React.useState('');

	let ref = React.createRef<HTMLDivElement>();
  
	const baseURL = 'http://okn.eecs.umich.edu:5000/'
	  


  	const popperContent = (data) => {
		// This functions renders the popup contents of the search results.
		// Inputs: data: search results returned by the back end.
		return (
			<List className={classes.popperlist}>
				{/* Table results */}
				{data.table.hits.hits.map(table => {
					return (
						<ListItem>
							<ListItemLink href={homeURL + "TableDetails/" + table._source.tbl_uri}>
								<ListItemText primary={table._source.tbl_name} secondary={<Typography variant="body2" color="textSecondary" noWrap>{table._source.tbl_desc}</Typography>} />
							</ListItemLink>
						</ListItem>
					)
				})}
				{/* Use case results. This part is not used in OKN for now. */}
				{/* {data.usecase.hits.hits.map(table => {
					return (
						<ListItem>
							<ListItemLink href={homeURL + "use_cases/" + table._source.usecase_name}>
								<ListItemText primary={table._source.usecase_name} secondary={table._source.usecase_desc} />
							</ListItemLink>
						</ListItem>
					)
				})} */}
				{/* Data base results */}
				{data.database.hits.hits.map(table => {
					return (
						<ListItem>
							<ListItemLink href={homeURL + "DatabaseDetails/" + table._source.db_uri}>
								<ListItemText primary={table._source.db_name} secondary={<Typography variant="body2" color="textSecondary" noWrap>{table._source.db_desc}</Typography>} />
							</ListItemLink>
						</ListItem>
					)
				})}
			</List>
		);
	}

	const handleChange = (event) => {
		// This event handles the input changes of the search box
		setSearchKey(event.target.value);
		if (event.target.value != ''){
			const url = baseURL + 'search/' + event.target.value;
			console.log(url);
			fetch(url)
				.then(response=>response.json())
				.then((jsonData) => {
				console.log(jsonData);
				if (jsonData.table.hits.hits.length !== 0 || jsonData.database.hits.hits.length){
					setOptions(popperContent(jsonData));
					setOpen(true);
				}
				else{
					setOpen(false);
					setOptions(<div/>)
				}
			})
		}
		else{
			setOpen(false);
			setOptions(<div/>);
		}
	};

	const handleKeydown = (event) => {
		// Go to the search results when the user presses "Enter" key
		if (event.keyCode === 13) {
			window.location.href = '/search_results/'  +  searchKey;
		}
	};

	function handleClickOutside(event) {
		// Close the search popup when clicking outside the popup
		if (ref.current && !ref.current.contains(event.target) && open) {
			setOpen(false);
		}
	}
	
	React.useEffect(() => {
		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
		// Unbind the event listener on clean up
		document.removeEventListener("mousedown", handleClickOutside);
		};
	});

  	return (
		<ThemeProvider theme={theme}>
			<AppBar position="relative" className={classes.appbar} >
				<Toolbar>
					<Typography variant="h6" noWrap>
						<TypographyLink href={homeURL} className="okn-link">
							OKN
						</TypographyLink>
					</Typography>
					{
						props.containSearch && 
						<div>
							<div ref={ref}>
							<Popper className={classes.popper} open={open} anchorEl={document.getElementById('input')} placement={'bottom-start'} transition>
								{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={350}>
									<Paper className={classes.popperPaper}>
										{options}
									</Paper>
								</Fade>
								)}
							</Popper>
							</div>
							<div className={classes.search} id='input'>        
								<IconButton type="submit"  aria-label="search" href={homeURL + 'search_results/' + searchKey} disabled={searchKey===''}>
									<SearchIcon />
								</IconButton>
								<InputBase
									placeholder="Search OKN…"
									classes={{
									root: classes.inputRoot,
									input: classes.inputInput,
									}}
									inputProps={{ 'aria-label': 'search' }}
									onChange={handleChange}
									onKeyDown={handleKeydown}
								/>
							</div>
					</div>}
				</Toolbar>
					
            </AppBar>
		</ThemeProvider>
  	)
}