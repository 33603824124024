import React from 'react';
import '../css/mapbox-gl.css';
import '../css/mapbox_demo.css';
import ReactMapboxGL, {ScaleControl, ZoomControl, Layer, GeoJSONLayer, Feature} from "react-mapbox-gl";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CommuteIcon from '@material-ui/icons/Commute';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import Switch from "@material-ui/core/Switch";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Head from './app_bar';

const Mapbox = ReactMapboxGL({
    accessToken: "pk.eyJ1IjoiZ2F2aW54aW5nIiwiYSI6ImNrMTRpZmIzYjBrbmYzb3Rjam5kcWpncTEifQ.lYHVMiN4mEcCaMQq-azOrA",
    attributionControl: false
});

const theme = createMuiTheme({
	palette: {
	  primary: {
		main: '#F3F3F3',
	  },
	},
});

const censusTractPaint = {
    'fill-opacity': 0.1,
    'fill-color': '#6F788A',
    'fill-outline-color': '#000000'
};

const polygonPaintDrive = {
    'fill-opacity': 0.5,
    'fill-color': '#6F788A',
    'fill-outline-color': '#FF0000'
};

const selectedCensusTractPaint = {
    'fill-opacity': 0.9,
    'fill-color': '#6F788A',
    'fill-outline-color': '#FF0000'
};

const selectedBorderPaint = {
    'line-width': 2,
    'line-color': '#000000',
};

const circlePaint = {
    'circle-radius': 3,
    'circle-color': '#E54E52',
    'circle-opacity': 0.8
};

const linePaint = {
    'line-color': '#4790E5',
    'line-width': 2
};


export interface State {
    center: [number, number];
    zoom: [number];
    selectedTract: any;
    selectedCoords: any;
    censusTractID: any;
    emp2000: any;
    work_point_rate: any;
    opportunity_score: any;
    loading: boolean;
    data: any;
    err_msg: string;
    selected_layer: [boolean, boolean];
    transportation: string;
    open: boolean;
    time: string;
    map: any
}

export interface Props {
    // tslint:disable-next-line:no-any
    onStyleLoad?: (map: any) => any;
}

export interface Promise {
    // tslint:disable-next-line:no-any
    status: string,
    result: Object
}

class Demo_Raj_Chetty extends React.Component<Props, State> {

    public state: State = {
        center: [-87.7, 41.83],
        zoom: [10],
        selectedTract: undefined,
        selectedCoords: undefined,
        censusTractID: null,
        emp2000: null,
        work_point_rate: null,
        opportunity_score: null,
        loading: true,
        data: {
            censusTractData: null,
            driveWork: null,
            busRoute: null
        },
        err_msg: '',
        selected_layer: [true, false], // 0: heatmap 1: bus route
        transportation: 'driving',
        open: false,
        time: '15',
        map: null
    };

    public componentDidMount(): void {
        this.setState({
            loading: true
        });
        let baseUrl = 'http://localhost:4000';
        if (window.location.hostname !== 'localhost') {
            baseUrl = 'http://galaxy.eecs.umich.edu:4000'
        }
        Promise.all([
            fetch(baseUrl + '/file/census_tract.geojson'),
            fetch(baseUrl + '/file/social_economy.json'),
            fetch(baseUrl + '/file/CTA_BusRoute.geojson')
        ]).then(([censusTractData, driveWork, busRoute]) => {
            return Promise.all([censusTractData.json(), driveWork.json(), busRoute.json()])
        }).then(([censusTractData, driveWork, busRoute]) => {
            const d = {
                censusTractData: censusTractData,
                driveWork: driveWork,
                busRoute: busRoute
            };
            this.setState({
                data: d,
                loading: false
            })
        }).catch((err) => {
            this.setState({
                err_msg: err.message,
                loading: false
            });
            console.log(err);
        });
    }

    private onTractClick = (censusTract: any, censusTractCoords: any) => {
        const {selectedTract} = this.state;
        if (selectedTract === censusTract) {
            this.setState({
                selectedTract: undefined,
                selectedCoords: undefined
            });
        } else {
            this.setState({
                selectedTract: censusTract,
                selectedCoords: censusTractCoords,
                selected_layer: [false, false]
            });
        }
    };

    private unSelect = () => {
        this.setState({
            selectedTract: undefined,
            selectedCoords: undefined,
            selected_layer: [true, false]
        });
    };


    private onMouseMove = (map, e) => {
        console.log(e);
        const features = map.queryRenderedFeatures(e.point).filter(x => x.source == 'census_tract');
        if (features.length > 0) {
            if (this.state.selectedTract === undefined) {
                this.setState({
                    censusTractID: features[0].properties.censusTractID,
                    emp2000: features[0].properties.emp2000,
                    work_point_rate: features[0].properties.work_point_rate,
                    opportunity_score: features[0].properties.opportunityScore
                });
            }
        }

    };

    private handleToggle = (value: number) => () => {
        var {selected_layer, map} = this.state;
        selected_layer[value] = !selected_layer[value];
        this.setState({
            selected_layer: selected_layer
        });
        // if (selected_layer[1]) {
        //     map.moveLayer('bus_route'); // move it to the top
        // }
    };

    private handleClick = () => {
        const {open} = this.state;
        this.setState({
            open: !open
        });
    };

    private selectTransportation = (event) => {
        this.setState({
            transportation: event.target.value
        });
    };

    private selectTravelTime = (event) => {
        this.setState({
            time: event.target.value
        });
    };

    private onStyleLoad = (map, e) => {
        this.setState({
            map: map
        })
    };


    private renderDemo = () => {
        const {center, zoom, selectedTract, selectedCoords, data, err_msg, selected_layer, transportation, open, time} = this.state;

        if (err_msg) {
            return <h1>{err_msg}</h1>
        }
        return (
            <div>
            <ThemeProvider theme = {theme}>
                <Box bgcolor="primary.main" color="primary.contrastText" className="box"></Box>
            </ThemeProvider>
            <Grid container >
                <Grid item xs={12}>
                    <Head containSearch={true}/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={0} alignItems='stretch' alignContent='center'>
                        <Grid item xs={3}>
                            <Grid container className='left_column' spacing={1}>
                            
                                <Grid item >
                                <List
                                    component="nav"
                                    classes={{
                                        root: "list-root-raj-chetty"
                                    }}
                                    style={{
                                        'position': 'absolute'
                                    }}
                                    subheader={<ListSubheader>Dashboard</ListSubheader>}
                                >
                                    <ListItem key={0} role={undefined} dense button onClick={this.handleToggle(0)}>
                                        <ListItemIcon>
                                            <TrendingUpIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={`Opportunity Score Heatmap`}/>
                                        <ListItemSecondaryAction>
                                            <Switch
                                                edge="end"
                                                onChange={this.handleToggle(0)}
                                                checked={selected_layer[0]}
                                                inputProps={{'aria-labelledby': 'switch-layer-opportunityScore'}}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem key={1} role={undefined} dense button onClick={this.handleToggle(1)}>
                                        <ListItemIcon>
                                            <DirectionsBusIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={`CTA_BusRoute`}/>
                                        <ListItemSecondaryAction>
                                            <Switch
                                                edge="end"
                                                onChange={this.handleToggle(1)}
                                                checked={selected_layer[1]}
                                                inputProps={{'aria-labelledby': 'switch-layer-busRoute'}}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem button onClick={this.handleClick}>
                                        <ListItemIcon>
                                            <DriveEtaIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary={open ? 'Transportation: ' : `${transportation} for ${time}mins`}/>
                                        {open ? <ExpandLess/> : <ExpandMore/>}
                                    </ListItem>
                                    <Collapse in={open} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <ListItem
                                                classes={{
                                                    root: 'nested'
                                                }}>
                                                <ListItemIcon>
                                                    <CommuteIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary={'Method: '}/>
                                                <ListItemSecondaryAction>
                                                    <Select
                                                        value={transportation}
                                                        onChange={this.selectTransportation}
                                                        inputProps={{
                                                            name: 'transportation',
                                                            id: 'age-simple',
                                                        }}
                                                    >
                                                        <MenuItem value={'driving'}>Driving</MenuItem>
                                                        <MenuItem value={'public transportation'}>Public</MenuItem>
                                                    </Select>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <ListItem
                                                classes={{
                                                    root: 'nested'
                                                }}>
                                                <ListItemIcon>
                                                    <CommuteIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary={'Travel time: '}/>
                                                <ListItemSecondaryAction>
                                                    <Select
                                                        value={time}
                                                        onChange={this.selectTravelTime}
                                                        inputProps={{
                                                            name: 'time',
                                                            id: 'age-simple',
                                                        }}
                                                    >
                                                        <MenuItem value={'15'}>15mins</MenuItem>
                                                        <MenuItem value={'30'}>30mins</MenuItem>
                                                    </Select>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                </List>
                                </Grid>
                                {this.state.censusTractID &&
                                <table className='hover-table-raj-chetty'>
                                    <tbody>
                                    <tr>
                                        <th><Typography variant="subtitle1">Tract ID:</Typography></th>
                                        <th><Typography variant="subtitle1">{this.state.censusTractID}</Typography></th>
                                    </tr>
                                    <tr>
                                        <th><Typography variant="subtitle1">Employment Rate:</Typography></th>
                                        <th><Typography variant="subtitle1">{this.state.emp2000 !== "null" ? this.state.emp2000 : "No data available"}</Typography></th>
                                    </tr>
                                    <tr>
                                        <th><Typography variant="subtitle1">Ratio of people who can get to work within {time} min
                                            by {transportation === 'driving' ? 'driving' : 'public transportation'}:
                                            </Typography>
                                        </th>
                                        <th><Typography variant="subtitle1">{JSON.parse(this.state.work_point_rate)[transportation === 'driving' ? 'drive_boundary' : 'public_boundary'][time] !== "null" ? JSON.parse(this.state.work_point_rate)[transportation === 'driving' ? 'drive_boundary' : 'public_boundary'][time] : "No data available"}</Typography></th>
                                    </tr>
                                    <tr>
                                        <th><Typography variant="subtitle1">Opportunity Score:</Typography></th>
                                        <th><Typography variant="subtitle1">{this.state.opportunity_score !== "null" ? this.state.opportunity_score : "No data available"}</Typography></th>
                                    </tr>
                                    </tbody>
                                </table>
                                }
                                <Grid item>
                                </Grid>
                            </Grid>
                        </Grid>
                        
                        <Grid item xs={9}>
                                <Mapbox
                                    style="mapbox://styles/mapbox/streets-v8"
                                    containerStyle={{
                                        height: '92.5%',
                                        width: '70vw',
                                        position: 'relative',
                                        padding: '2%'
                                    }}
                                    center={center}
                                    zoom={zoom}
                                    onMouseMove={this.onMouseMove}
                                    onStyleLoad={this.onStyleLoad}
                                >

                                    {selected_layer[0] ? (<GeoJSONLayer
                                        id='heatmap'
                                        data={data.censusTractData}
                                        before='waterway-label'
                                        fillPaint={{
                                            "fill-color": [
                                                "step",
                                                ["get", "traveltime15_2010"],
                                                '#fff7ec', 0.1,
                                                '#fee8c8', 0.2,
                                                '#fdd49e', 0.3,
                                                '#fdbb84', 0.4,
                                                '#fc8d59', 0.5,
                                                '#ef6548', 0.6,
                                                '#d7301f', 0.7,
                                                '#b30000', 0.8,
                                                '#7f0000', 0.9,
                                                '#4b0000'
                                            ],
                                            "fill-opacity": 0.7,
                                        }}
                                    />) : <div></div>}


                                    <Layer type="fill" paint={censusTractPaint} id="census_tract" before='waterway-label'>
                                        {
                                            !selectedTract && data.censusTractData.features.map((censusTract) => {
                                                return <Feature
                                                    key={censusTract.properties['geoid10']}
                                                    coordinates={censusTract.geometry.coordinates[0]}
                                                    properties={{
                                                        censusTractID: censusTract.properties['geoid10'],
                                                        emp2000: censusTract.properties['emp2000'],
                                                        work_point_rate: censusTract.properties['work_point_rate'],
                                                        opportunityScore: censusTract.properties['traveltime15_2010']
                                                    }}
                                                    onClick={this.onTractClick.bind(this, censusTract.properties['geoid10'], censusTract.geometry.coordinates[0])}
                                                />
                                            })
                                        }
                                    </Layer>


                                    <Layer type="line" paint={linePaint} id="bus_route" before='waterway-label'>
                                        {
                                            data && selected_layer[1] && data.busRoute.features.map((busRoute) => {
                                                return busRoute.geometry.coordinates.map((line) => {
                                                    return <Feature
                                                        key={busRoute.properties['OBJECTID']}
                                                        coordinates={line}
                                                        properties={{
                                                            busRoute: busRoute.properties['OBJECTID']
                                                        }}
                                                    />
                                                });
                                            })
                                        }
                                    </Layer>

                                    <Layer type="fill" paint={selectedCensusTractPaint} id="selected_census_tract" before='waterway-label'>
                                        {
                                            selectedTract && (
                                                <Feature
                                                    key="selected"
                                                    coordinates={selectedCoords}
                                                    onClick={this.unSelect}
                                                />
                                            )
                                        }
                                    </Layer>

                                    <Layer type="line" paint={selectedBorderPaint} id="selected_census_tract_border" before='waterway-label'>
                                        {
                                            selectedTract && (
                                                <Feature
                                                    key="selected"
                                                    coordinates={selectedCoords[0]}
                                                    // onClick={this.unSelect}
                                                />
                                            )
                                        }
                                    </Layer>

                                    <Layer type="fill" paint={polygonPaintDrive} before='waterway-label'>
                                        {
                                            selectedTract && (
                                                data.driveWork[selectedTract][transportation === 'driving' ? 'drive_boundary' : 'public_boundary'][time].map((polygen) => {
                                                    return <Feature coordinates={polygen} onClick={this.unSelect}/>
                                                })

                                            )
                                        }

                                    </Layer>

                                    <Layer type="circle" paint={circlePaint} before='waterway-label'>
                                        {
                                            selectedTract && data.driveWork[selectedTract]["work_points"].map((point) => {
                                                return <Feature coordinates={point}/>;
                                            })
                                        }
                                    </Layer>


                                </Mapbox>


                                

                                { selected_layer[0] &&
                                <div id='opportunity-legend' className="legend">
                                    <h4>Opportunity Score</h4>
                                    <div><span style={{background: '#4b0000'}}></span>1</div>
                                    <div><span style={{background: '#7f0000'}}></span>0.9</div>
                                    <div><span style={{background: '#b30000'}}></span>0.8</div>
                                    <div><span style={{background: '#d7301f'}}></span>0.7</div>
                                    <div><span style={{background: '#ef6548'}}></span>0.6</div>
                                    <div><span style={{background: '#fc8d59'}}></span>0.5</div>
                                    <div><span style={{background: '#fdbb84'}}></span>0.4</div>
                                    <div><span style={{background: '#fdd49e'}}></span>0.3</div>
                                    <div><span style={{background: '#fee8c8'}}></span>0.2</div>
                                    <div><span style={{background: '#fff7ec'}}></span>0.1</div>
                                    <div><span style={{background: '#000000'}}></span>0</div>
                                </div>
                                }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            </div>);
    };


    public render(): React.ReactNode {
        const {loading} = this.state;
        if (loading) {
            return <div/>
        } else {
            return this.renderDemo()
        }
    }
}


export default Demo_Raj_Chetty;
