import React from 'react';
import '../css/mapbox-gl.css';
import '../css/mapbox_demo.css';
import ReactMapboxGL, { ScaleControl, ZoomControl, Layer, Feature } from "react-mapbox-gl";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import CommuteIcon from '@material-ui/icons/Commute';
import WorkIcon from '@material-ui/icons/Work';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Head from './app_bar';
import Chip from '@material-ui/core/Chip';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';




const Mapbox = ReactMapboxGL({
    accessToken: "pk.eyJ1IjoiZ2F2aW54aW5nIiwiYSI6ImNrMTRpZmIzYjBrbmYzb3Rjam5kcWpncTEifQ.lYHVMiN4mEcCaMQq-azOrA"
});

const censusTractPaint = {
    'fill-opacity': 0.3,
    'fill-color': '#6F788A',
    'fill-outline-color': '#000000'
};

const polygonPaintDrive = {
    'fill-opacity': 0.5,
    'fill-color': '#6F788A',
    'fill-outline-color': '#FF0000'
};

const selectedCensusTractPaint = {
    'fill-opacity': 0.9,
    'fill-color': '#6F788A',
    'fill-outline-color': '#FF0000'
};

const selectedBorderPaint = {
    'line-width': 2,
    'line-color': '#000000',
};

const circlePaint = {
    'circle-radius': 3,
    'circle-color': '#E54E52',
    'circle-opacity': 0.8
};

const linePaint = {
    'line-color': '#4790E5',
    'line-width': 2
};

const sector_map = {
    'Agriculture, Forestry, Fishing and Hunting':'CNS01',
    'Mining, Quarrying, and Oil and Gas Extraction':'CNS02',
    'Utilities':'CNS03',
    'Construction':'CNS04', 
    'Manufacturing':'CNS05', 
    'Wholesale Trade':'CNS06', 
    'Retail Trade':'CNS07', 
    'Transportation and Warehousing':'CNS08', 
    'Information':'CNS09', 
    'Finance and Insurance':'CNS10', 
    'Real Estate and Rental and Leasing':'CNS11', 
    'Professional, Scientific, and Technical Services':'CNS12', 
    'Management of Companies and Enterprises':'CNS13', 
    'Administrative and Support and Waste Management and Remediation Services':'CNS14', 
    'Educational Services':'CNS15', 
    'Health Care and Social Assistance':'CNS16', 
    'Arts, Entertainment, and Recreation':'CNS17', 
    'Accommodation and Food Services':'CNS18', 
    'Other Services (except Public Administration)':'CNS19',
    'Public Administration':'CNS20'
};

const sector_names = [
    'Agriculture, Forestry, Fishing and Hunting',
    'Mining, Quarrying, and Oil and Gas Extraction',
    'Utilities',
    'Construction', 
    'Manufacturing', 
    'Wholesale Trade', 
    'Retail Trade', 
    'Transportation and Warehousing', 
    'Information', 
    'Finance and Insurance', 
    'Real Estate and Rental and Leasing', 
    'Professional, Scientific, and Technical Services', 
    'Management of Companies and Enterprises', 
    'Administrative and Support and Waste Management and Remediation Services', 
    'Educational Services', 
    'Health Care and Social Assistance', 
    'Arts, Entertainment, and Recreation', 
    'Accommodation and Food Services', 
    'Other Services (except Public Administration)', 
    'Public Administration'
]

const theme = createMuiTheme({
	palette: {
	  primary: {
		main: '#F3F3F3',
	  },
	},
});


export interface State {
    center: [number, number];
    zoom: [number];
    selectedTract: any;
    selectedCoords: any;
    censusTractID: any;
    education: any;
    sector_info: any;
    loading: boolean;
    data: any;
    err_msg: string;
    selected_layer: [boolean, boolean];
    transportation: string;
    transportation_open: boolean;
    sector_list_open: boolean;
    time: string;
    sectors: any
}

export interface Props {
    // tslint:disable-next-line:no-any
    usecase_details: any
}

export interface Promise {
    // tslint:disable-next-line:no-any
    status: string,
    result: Object
}

class TalentGap extends React.Component<Props, State> {

    public state: State = {
        center: [-87.63004035576937, 41.74247518248426],
        zoom: [11],
        selectedTract: undefined,
        selectedCoords: undefined,
        censusTractID: null,
        education: null,
        sector_info: null,
        loading: true,
        data: {
            censusTractData: null,
            driveWork: null,
            busRoute: null,
        },
        err_msg: '',
        selected_layer: [true, true],
        transportation: 'driving',
        transportation_open: false,
        sector_list_open: false,
        time: '15',
        sectors: {
            'Agriculture, Forestry, Fishing and Hunting': false,
            'Mining, Quarrying, and Oil and Gas Extraction': false,
            'Utilities': false,
            'Construction': false, 
            'Manufacturing': false, 
            'Wholesale Trade': false, 
            'Retail Trade': false, 
            'Transportation and Warehousing': false, 
            'Information': false, 
            'Finance and Insurance': false, 
            'Real Estate and Rental and Leasing': false, 
            'Professional, Scientific, and Technical Services': false, 
            'Management of Companies and Enterprises': false, 
            'Administrative and Support and Waste Management and Remediation Services': false, 
            'Educational Services': false, 
            'Health Care and Social Assistance': false, 
            'Arts, Entertainment, and Recreation': false, 
            'Accommodation and Food Services': false, 
            'Other Services (except Public Administration)': false, 
            'Public Administration': false
        }
    };

    public componentDidMount(): void {
        this.setState({
            loading: true
        });
        let baseUrl = 'http://localhost:4000';
        if (window.location.hostname !== 'localhost') {
            baseUrl = 'http://okn.eecs.umich.edu:4000'
        }
        Promise.all([
            fetch(baseUrl + '/file/census_tract.geojson'),

        ]).then(([censusTractData]) => {
            return Promise.all([censusTractData.json()])
        }).then(([censusTractData]) => {
            const d = {
                censusTractData: censusTractData,
            };
            this.setState({
                data: d,
                loading: false
            })
        }).catch((err) => {
            console.log(err);
        });
    }

    private onTractClick = (censusTract: any, censusTractCoords: any) => {
        const { selectedTract } = this.state;
        if (selectedTract === censusTract) {
            this.setState({
                selectedTract: undefined,
                selectedCoords: undefined

            });
        } else {
            this.setState({
                selectedTract: censusTract,
                selectedCoords: censusTractCoords
            });
        }
    };

    private unSelect = () => {
        this.setState({
            selectedTract: undefined,
            selectedCoords: undefined
        });
    };


    private onMouseMove = (map: any, e: any) => {
        const features = map.queryRenderedFeatures(e.point);
        if (features.length > 0) {
            if (this.state.selectedTract === undefined) {
                this.setState({
                    censusTractID: features[0].properties.censusTractID,
                    education: features[0].properties.education,
                    sector_info: features[0].properties.sector_info
                });
            }
        }

    };

    private handleToggle = (value: string) => () => {
        var new_sectors = this.state.sectors
        if (new_sectors[value]){
            new_sectors[value] = false;
        }
        else {
            new_sectors[value] = true;
        }
        this.setState({
            sectors : new_sectors
        });
    };

    private handleTransportationClick = () => {
        const { transportation_open } = this.state;
        this.setState({
            transportation_open: !transportation_open
        });
    };

    private handleSectorClick = () => {
        const { sector_list_open } = this.state;
        this.setState({
            sector_list_open: !sector_list_open
        });
    };

    private selectTransportation = (event: any) => {
        this.setState({
            transportation: event.target.value
        });
    };

    private selectTravelTime = (event: any) => {
        this.setState({
            time: event.target.value
        });
    };


    private renderDemo = () => {
        const { center, zoom, selectedTract, selectedCoords, data, err_msg, transportation, transportation_open, sector_list_open, time } = this.state;

        if (err_msg) {
            return <h1>{err_msg}</h1>
        }
        const use_case_data = this.props.usecase_details._source;
        return (
            <div>
            <ThemeProvider theme = {theme}>
                <Box bgcolor="primary.main" color="primary.contrastText" className="box"></Box>
            </ThemeProvider>
            <Grid container >
                <Grid item xs={12}>
                    <Head containSearch={true}/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={0} alignItems='stretch' alignContent='center'>
                        <Grid item xs={3}>
                            <Grid container className='left_column' spacing={1}>
                            
                                <Grid item >
                                    <div className='usecase-details'>
                                        <Typography variant='h6'>Use case details:</Typography>
                                        <Typography variant="subtitle1" gutterBottom> 
                                            Name
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            {use_case_data.usecase_name}
                                        </Typography>
                                        <br/>
                                        <Typography variant="subtitle1" gutterBottom> 
                                            Descritpion
                                        </Typography>
                                        <Typography variant="body2" gutterBottom>
                                            {use_case_data.usecase_desc}
                                        </Typography>
                                        <br/>
                                        <Typography variant="subtitle1" gutterBottom> 
                                            Tags
                                        </Typography>
                                        <div>
                                            {use_case_data.usecase_tags.map((tag: any)=>{
                                                return <Chip label={tag} />;
                                            })}
                                        </div>
                                        <br/>
                                        <Typography variant="subtitle1" gutterBottom> 
                                            Related Tables
                                        </Typography>
                                        <div>
                                            {use_case_data.table_names.map((name: string, index: number)=>{
                                                return <a href={'http://'+window.location.host+'/details/'+use_case_data.table_uris[index]}>{name}</a>;
                                            })}
                                        </div>
                                    </div>
                                </Grid>
                                <Divider/>
                                <Grid item >
                                    <List
                                        component="nav"
                                        classes={{
                                            root: "list-root"
                                        }}
                                        style={{
                                            'position': 'absolute'
                                        }}
                                        subheader={<ListSubheader>Dashboard</ListSubheader>}
                                    >
                                        <ListItem button onClick={this.handleTransportationClick}>
                                            <ListItemIcon>
                                                <DriveEtaIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={transportation_open ? 'Transportation: ' : `${transportation} for ${time}mins`}/>
                                            {transportation_open ? <ExpandLess /> : <ExpandMore />}
                                        </ListItem>
                                        <Collapse in={transportation_open} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                <ListItem
                                                    classes={{
                                                        root: 'nested'
                                                    }}>
                                                    <ListItemIcon>
                                                        <CommuteIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary={'Method: '} />
                                                    <ListItemSecondaryAction>
                                                        <Select
                                                            value={transportation}
                                                            onChange={this.selectTransportation}
                                                            inputProps={{
                                                                name: 'transportation',
                                                                id: 'age-simple',
                                                            }}
                                                        >
                                                            <MenuItem value={'driving'}>Driving</MenuItem>
                                                            <MenuItem value={'public transportation'}>Public</MenuItem>
                                                        </Select>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                                <ListItem
                                                    classes={{
                                                        root: 'nested'
                                                    }}>
                                                    <ListItemIcon>
                                                        <CommuteIcon />
                                                    </ListItemIcon>
                                                    <ListItemText primary={'Travel time: '} />
                                                    <ListItemSecondaryAction>
                                                        <Select
                                                            value={time}
                                                            onChange={this.selectTravelTime}
                                                            inputProps={{
                                                                name: 'time',
                                                                id: 'age-simple',
                                                            }}
                                                        >
                                                            <MenuItem value={'15'}>15mins</MenuItem>
                                                            <MenuItem value={'30'}>30mins</MenuItem>
                                                        </Select>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            </List>
                                        </Collapse>
                                        <ListItem button onClick={this.handleSectorClick}>
                                            <ListItemIcon>
                                                <WorkIcon />
                                            </ListItemIcon>
                                            <ListItemText primary={'Please select the sector population you are interested in:'} />
                                            {sector_list_open ? <ExpandLess /> : <ExpandMore />}
                                        </ListItem>
                                        <Collapse in={sector_list_open} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {sector_names.map(value => {
                                                    const labelId = `checkbox-list-secondary-label-${value}`;
                                                    return (
                                                    <ListItem key={value} classes={{
                                                        root: 'nested'
                                                    }}>
                                                        <ListItemIcon>
                                                            <WorkIcon />
                                                        </ListItemIcon>
                                                        <ListItemText id={labelId} primary={`${value}`} />
                                                        <ListItemSecondaryAction>
                                                        <Checkbox
                                                            edge="end"
                                                            onChange={this.handleToggle(value)}
                                                            checked={this.state.sectors[value]}
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                    );
                                                })}
                                            </List>
                                        </Collapse>
                                    </List>
                                </Grid>
                                <Divider/>
                                <Grid item >
                                    <div className='hover-table'>
                                        {this.state.censusTractID &&
                                            <table >
                                                <tbody>
                                                    <tr>
                                                        <th><Typography variant="subtitle1">Tract ID:</Typography></th>
                                                        <th><Typography variant="subtitle1">{this.state.censusTractID}</Typography></th>
                                                    </tr>
                                                    <tr>
                                                        <th><Typography variant="subtitle1">Population Age 25+ with Bachelors degree</Typography></th>
                                                        <th><Typography variant="subtitle1">{JSON.parse(this.state.education)[this.state.transportation === 'driving' ? 'drive_boundary' : 'public_boundary'][this.state.time]}</Typography></th>
                                                    </tr>

                                                    {sector_names.map((sector_name: any)=>{
                                                        const sector = sector_map[sector_name];
                                                        return (
                                                            this.state.sectors[sector_name] &&
                                                            <tr>
                                                                <th><Typography variant="subtitle1">{sector_name} Population</Typography></th>
                                                                <th><Typography variant="subtitle1">{JSON.parse(this.state.sector_info)[this.state.transportation === 'driving' ? 'drive_boundary' : 'public_boundary'][this.state.time][sector]}</Typography></th>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                        }
                                    </div>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs = {9}>
                            <Mapbox
                                style="mapbox://styles/mapbox/streets-v8"
                                containerStyle={{
                                    height: '92.5%',
                                    width: '70vw',
                                    position: 'relative',
                                    padding: '2%'
                                }}
                                center={center}
                                zoom={zoom}
                                onMouseMove={this.onMouseMove}
                            >

                                <ZoomControl />
                                <ScaleControl />
                                <Layer type="fill" paint={censusTractPaint}>
                                    {
                                        !selectedTract && data.censusTractData.features.map((censusTract: any) => {
                                            return <Feature
                                                key={censusTract.properties['geoid10']}
                                                coordinates={censusTract.geometry.coordinates[0]}
                                                properties={{
                                                    censusTractID: censusTract.properties['geoid10'],
                                                    education: censusTract.properties['education'],
                                                    sector_info: censusTract.properties['sectors'],
                                                }}
                                                onClick={this.onTractClick.bind(this, censusTract.properties['geoid10'], censusTract.geometry.coordinates[0])}
                                            />
                                        })
                                    }
                                </Layer>


                                <Layer type="fill" paint={selectedCensusTractPaint}>
                                    {
                                        selectedTract && (
                                            <Feature
                                                key="selected"
                                                coordinates={selectedCoords}
                                                onClick={this.unSelect}
                                            />
                                        )
                                    }
                                </Layer>

                                <Layer type="line" paint={selectedBorderPaint}>
                                    {
                                        selectedTract && (
                                            <Feature
                                                key="selected"
                                                coordinates={selectedCoords[0]}
                                            // onClick={this.unSelect}
                                            />
                                        )
                                    }
                                </Layer>

                            </Mapbox>
                        </Grid>
                    </Grid> 
                </Grid>               
            </Grid>
            </div>
            );
            
    };


    public render(): React.ReactNode {
        const { loading } = this.state;
        if (loading) {
            return <div/>
        } else {
            return this.renderDemo()
        }
    }
}


export default TalentGap;