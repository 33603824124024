import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem, {ListItemProps} from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import {RouteComponentProps} from 'react-router-dom';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

import Box from '@material-ui/core/Box';

import Head from './app_bar';

import Chip from '@material-ui/core/Chip';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#F3F3F3',
        },
    },
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        formControl: {
            margin: theme.spacing(3),
            paddingLeft: theme.spacing(5)
        },
        box: {
            position: 'absolute',
            top: '0vh',
            height: '100vh',
            width: '15vw',
            marginTop: theme.spacing(0),
            zIndex: -1,
        },
        filter_head: {
            paddingTop: theme.spacing(5),
            paddingLeft: theme.spacing(3.5)
        },
        search_results:{
            paddingTop: theme.spacing(5),
        }
    }),
);

function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
    return <ListItem button component="a" {...props} />;
}

function Results(props: {data: any, searchKey: string}) {

    const classes = useStyles();
    const data = props.data;
    const searchKey = props.searchKey;
    const homeURL = 'http://' + window.location.host + '/';

    const [state, setState] = React.useState({
        tables: true,
        usecases: true,
        databases: true
    });

    const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({ ...state, [name]: event.target.checked });
    };
    console.log(searchKey);
    return (
        <div>
            <ThemeProvider theme = {theme}>
                <Box bgcolor="primary.main" color="primary.contrastText" className={classes.box}></Box>
            </ThemeProvider>
            <Grid container className={classes.root} spacing={0}>
                <Grid item xs={12}>
                    <Head containSearch={true} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems='stretch' spacing={0}>
                        <Grid item xs={2}>
                            <Typography variant='h6' className={classes.filter_head}>
                                Filter By:
                            </Typography>
                            <FormControl component="fieldset" className={classes.formControl}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox checked={state.tables} onChange={handleChange('tables')} value="tables" />}
                                        label="Tables"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={state.databases} onChange={handleChange('databases')} value="databases" />}
                                        label="Databases"
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={state.usecases} onChange={handleChange('usecases')} value="usecases" />}
                                        label="Use cases"
                                    />
                                </FormGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={10} className={classes.search_results}>
                            <Typography variant='h6'>
                                Search results for "{searchKey}":
                            </Typography>
                            <List>
                                {
                                    state.tables && data.table.hits.hits.map((result: any)=>{
                                        return (
                                            <div>
                                                <ListItem>
                                                    <ListItemLink href={homeURL + "TableDetails/" + result._source.tbl_uri}>
                                                        <ListItemText primary = {result._source.tbl_name} secondary = {result._source.tbl_desc}/>
                                                        <Chip label="table" color='primary'/>
                                                    </ListItemLink>
                                                </ListItem>
                                                <Divider />
                                            </div>
                                        );
                                    })
                                }
                                {
                                    state.usecases && data.usecase.hits.hits.map((result: any)=>{
                                        return (
                                            <div>
                                                <ListItem>
                                                    <ListItemLink href={homeURL + "use_cases_details"}>
                                                        <ListItemText primary = {result._source.usecase_name} secondary = {result._source.usecase_desc}/>
                                                        <Chip label="case study" color='primary'/>
                                                    </ListItemLink>
                                                </ListItem>
                                                <Divider />
                                            </div>
                                        );
                                    })
                                }
                                {
                                    state.databases && data.database.hits.hits.map((result: any)=>{
                                        return (
                                            <div>
                                                <ListItem>
                                                    <ListItemLink href={homeURL +"DatabaseDetails/" + result._source.db_uri}>
                                                        <ListItemText primary = {result._source.db_name} secondary = {result._source.db_desc}/>
                                                        <Chip label="database" color='primary'/>
                                                    </ListItemLink>
                                                </ListItem>
                                                <Divider />
                                            </div>
                                        );
                                    })
                                }
                            </List>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

type Search_results_params = {
    key: string
}



class Results_Page_Old extends React.Component<RouteComponentProps<Search_results_params>>{
    public state = {
        loading: true,
        data: {},
    }

    public componentDidMount(): void {
        this.setState({
            loading: true
        });
        // let baseUrl = 'http://localhost:4000/file/data.json';
        let baseUrl = 'http://okn.eecs.umich.edu:5000/search_old/';
        const {key} = this.props.match.params;
        let url = baseUrl + key
        fetch(url)
            .then(response => response.json())
            .then((jsonData) => {
                this.setState({
                    loading: false,
                    data: jsonData
                })
            })
            .catch((error) => {
                console.error(error)
            });
    }
    public render() : React.ReactNode{
        if (this.state.loading){
            return <div/>
        }
        else{
            return <Results data = {this.state.data} searchKey={this.props.match.params.key}/>
        }
    }
}


export default Results_Page_Old;