import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { createMuiTheme } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';

import Head from './app_bar';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 0,
        },
        list: {
            padding: theme.spacing(4)
        },
        project_item: {
            width: "90vw",
            height: "250px",
            backgroundColor: '#F3F3F3',
            margin: theme.spacing(2),
            padding: theme.spacing(2)
        },
        imageContainer: {
            overflow: "hidden",
            height: '248px',
        },
        image: {
            position: 'relative',
            width: '100%',
        },
        text_container: {
            position: "relative",
            height: "280px",
            width: '99%',
            // padding: theme.spacing(2)
        },
        text: {
            position: 'relative',
            height: '70%',
            maxHeight: '70%',
            overflow: 'auto'
        },
        bottom_bar: {
            position: 'relative',
            height: '30px',
            width: '98%',
            backgroundColor: '#dedede',
            padding: theme.spacing(1),
            bottom: theme.spacing(0),
        },
        link: {
            position:'relative',
            width: '99%',
            height:'100%',
            textAlign: 'end',
            display: 'inline-block'
        },
        link_text: {
            textDecoration:'none',
            color: 'black',
        },
        descriptionText: {
            marginTop: 15,
        }
    }),
);



function Project_item(props: {img: any, name:any, description: string, url:string}){
    const classes = useStyles();
    const homeURL = 'http://' + window.location.host + '/';
    return (
        <div className={classes.project_item}>
            <Grid container justify='center' spacing={2}>
                <Grid item xs={3}>
                    <div className={classes.imageContainer}>
                        <img src={props.img} className={classes.image}/>
                    </div>
                </Grid>
                <Grid item xs={9}>
                    <div className={classes.text_container}>
                        <div className = {classes.text}>
                            <Typography variant="h6">{props.name}</Typography>
                            <div className = {classes.descriptionText}><Typography variant="subtitle1" color='textSecondary'>{props.description}</Typography></div>
                        </div>
                        <div className={classes.bottom_bar}>
                            <div className = {classes.link}>
                                <a href={homeURL + props.url} className={classes.link_text}>
                                    <Typography variant='subtitle1'>Read more    <ArrowForwardIcon style={{"verticalAlign":"middle"}}/></Typography>
                                </a>
                            </div>
                        </div>

                    </div>
                </Grid>
            </Grid>
        </div>
    )
}


function Projects_Page() {

    const classes = useStyles();

    // All projects are hard coded here
    return (
        <div>
            <Grid container className={classes.root} spacing={0}>
                <Grid item xs={12}>
                    <Head containSearch={true} />
                </Grid>
                <Grid item xs={12}>
                    <Grid container justify='center' spacing={2} className={classes.list}>
                        <Project_item img="images/bigmap.png" name="Accessibility" description="These data were created as part of a study at the University of Minnesota that examined the accessibility to jobs by transit in the 50 largest (by population) metropolitan areas in the United States." url="bigmap"/>
                        <Project_item img="images/photo-of-people-near-wooden-table-3184418.jpg" name="Travel Demand" description="The Bureau of Transportation Statistics (BTS) developed Local Area Transportation Characteristics for Households (LATCH Survey) that allows estimation of the average weekday household person trips, vehicle trips, person miles traveled, and vehicle miles traveled (per day), for all Census tracts in the United States. LATCH combines the National Household Travel Survey (NHTS) data along with American Community Survey (ACS) data from the Census Bureau." url="bigmap"/>
                        <Project_item img="images/black-kick-scooters-parked-near-body-of-water-2547627.jpg" name="Detroit Shared Micromobility Pilot and Regulation" description="For-hire, dockless scooters launched in Detroit in July of 2018 as part of a wider roll-out of shared micromobility by private companies across the United States. Like ridesourcing services before them, dockless scooter companies introduced their initial fleets in multiple cities in rapid succession, in some instances outpacing local or state regulations." url="use_cases_details"/>
                        <Project_item img="images/timelapse-photography-of-cars-in-tunnel-1253049.jpg" name="Fatal Crash Risk" description="Fatal Analysis Reporting System (FARS) is a nationwide census providing National Highway Traffic Safety Administration (NHTSA), Congress and the American public yearly data regarding fatal injuries suffered in motor vehicle traffic crashes." url="bigmap"/>
                        <Project_item img="images/person-holding-smartphone-861112.jpg" name="Annual On-road CO2 Emission" description="DARTE provides a 38-year, 1-km resolution inventory of annual on-road CO2 emissions for the conterminous United States based on roadway-level vehicle traffic data and state-specific emissions factors for multiple vehicle types on urban and rural roads as compiled in the Database of Road Transportation Emissions (DARTE)." url="bigmap"/>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}


export default Projects_Page;