import React from 'react';
import { fade, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem, {ListItemProps} from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

import Typography from '@material-ui/core/Typography';

import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

import Popper from '@material-ui/core/Popper';
import IconButton from '@material-ui/core/IconButton';

import Head from './app_bar';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			flexGrow: 1,
		},
		headerBar:{
			zIndex: 1,
		},
		backgroundContainer:{
			display: 'block',
			position: 'relative',
			marginLeft: 'auto',
			marginRight: 'auto',
			height: '45vh',
			width: '100%',
			overflow: 'hidden',
		},
		background: {
			position: 'absolute',
			left: -10,
			bottom:-10,
			width: '125%',	
			filter: "blur(8px)",
			WebkitFilter: "blur(8px)",
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',		
		},
		content: {
			position: "absolute",
			height: '50vh',
			top: "25px",
			zIndex: 2,
		},
		image: {
			position: 'relative',
			height: '100%',
			width: '100%',
			objectFit: 'cover',
		},
		search_root: {
			padding: '2px 4px',
			display: 'flex',
			alignItems: 'center',
			marginBottom: theme.spacing(1),
		},
		input: {
			marginLeft: theme.spacing(1),
			flex: 1,
		},
		iconButton: {
			padding: 10,
		},

		search: {
			position: 'relative',
			borderRadius: theme.shape.borderRadius,
			backgroundColor: fade(theme.palette.common.white, 0.15),
			'&:hover': {
				backgroundColor: fade(theme.palette.common.white, 0.25),
			},
			marginRight: theme.spacing(2),
			marginLeft: 0,
			width: '100%',
			[theme.breakpoints.up('sm')]: {
				marginLeft: theme.spacing(3),
				width: 'auto',
			},
		},
		searchIcon: {
			width: theme.spacing(7),
			height: '100%',
			position: 'absolute',
			pointerEvents: 'none',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		inputRoot: {
			color: 'inherit',
		},
		inputInput: {
			padding: theme.spacing(1, 1, 1, 7),
			transition: theme.transitions.create('width'),
			width: '100%',
			[theme.breakpoints.up('md')]: {
				width: 200,
			},
		},
		typography: {
			padding: theme.spacing(2),
		},
		popper:{
			width: '50vw',
			maxHeight: '30vh',
			overflow: 'auto',
		},
		title: {
			textAlign: 'center',
			fontFamily: "Segoe UI",
			fontStyle: "normal",
			fontWeight: "bold",
			fontSize: "35px",
			lineHeight: "47px",
			color: "#FFFFFF",
			textShadow: "0px 2.91667px 2.91667px rgba(0, 0, 0, 0.25)",
		},
		descriptions: {
			textAlign: 'left',
			fontFamily: "Segoe UI",
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "13.125px",
			lineHeight: "17px",
			color: "#FFFFFF",

			textShadow: "0px 2.91667px 2.91667px rgba(0, 0, 0, 0.25)"
		},
		bottom: {
			paddingTop: theme.spacing(8)
		}
	}),
);

function ListItemLink(props: ListItemProps<'a', { button?: true }>) {
	return <ListItem button component="a" {...props} />;
  }

export default function Search_Page() {
	const classes = useStyles();
	const [searchKey, setSearchKey] = React.useState('');
	const [open, setOpen] = React.useState(false);
	const [options, setOptions] = React.useState(<div />);

	let ref = React.createRef<HTMLDivElement>();

	const baseURL = 'http://okn.eecs.umich.edu:5000/search/'
	const homeURL = 'http://' + window.location.host + '/'

	const popperContent = (data) => {
		return (
			<List>
				{data.table.hits.hits.map(table => {
					return (
						<ListItem>
							<ListItemLink href={homeURL + "TableDetails/" + table._source.tbl_uri}>
								<ListItemText primary={table._source.tbl_name} secondary={<Typography variant="body2" color="textSecondary" noWrap>{table._source.tbl_desc}</Typography>} />
							</ListItemLink>
						</ListItem>
					)
				})}
				{/* {data.usecase.hits.hits.map(table => {
					return (
						<ListItem>
							<ListItemLink href={homeURL + "use_cases/" + table._source.usecase_name}>
								<ListItemText primary={table._source.usecase_name} secondary={table._source.usecase_desc} />
							</ListItemLink>
						</ListItem>
					)
				})} */}
				{data.database.hits.hits.map(table => {
					return (
						<ListItem>
							<ListItemLink href={homeURL + "DatabaseDetails/" + table._source.db_uri}>
								<ListItemText primary={table._source.db_name} secondary={<Typography variant="body2" color="textSecondary" noWrap>{table._source.db_desc}</Typography>} />
							</ListItemLink>
						</ListItem>
					)
				})}
			</List>
		);
	}

	const handleChange = (event) => {
		setSearchKey(event.target.value);
		if (event.target.value !== '') {
			const url = baseURL + event.target.value;
			fetch(url)
				.then(response => response.json())
				.then((jsonData) => {
					if (jsonData.table.hits.hits.length !== 0 || jsonData.database.hits.hits.length) {
						setOptions(popperContent(jsonData));
						setOpen(true);
					}
					else {
						setOpen(false);
					}
				})
		}
		else {
			setOpen(false);
			setOptions(<div />);
		}
	};

	const handleKeydown = (event) => {
		if (event.keyCode === 13) {
			window.location.href = '/search_results/'  +  searchKey;
		}
	};

	function handleClickOutside(event) {
		if (ref.current && !ref.current.contains(event.target) && open) {
			setOpen(false);
		}
	}
	
	React.useEffect(() => {
		// Bind the event listener
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
		// Unbind the event listener on clean up
		document.removeEventListener("mousedown", handleClickOutside);
		};
	});

	return (
		<Grid container className={classes.root}>
			<Grid item xs={12} className={classes.headerBar}>
				<Head containSearch={false} />
			</Grid>
			<Grid item xs={12}>
				<div className={classes.backgroundContainer}>
					<img src="images/architecture-auto-automobiles-bridge-210182.jpg" className={classes.background}/>
				</div>
				<Grid container className={classes.content} justify="center" alignItems="center">
					<Grid item xs={6}>
						<div ref={ref} className='search-page-search-container'>
						<Popper className={classes.popper} open={open} anchorEl={document.getElementById('paper')} placement={'bottom-start'} transition>
							{({ TransitionProps }) => (
								<Fade {...TransitionProps} timeout={350}>
									<Paper>
										{options}
									</Paper>
								</Fade>
							)}
						</Popper>
						</div>

						<p className={classes.title}>Transportation Equity Open Knowledge Network</p>
						<p className={classes.descriptions}>Our diverse team of academic, non-profit, private and public sector partners aims to accelerate and support an inclusive transportation equity community of practice for the connected and driverless vehicle era, by creating a peer learning and capacity-building platform, that leverages a publicly held open big-data repository and knowledge network that links transportation to important outcomes for people and cities.</p>
						<Paper id='paper' component="div" className={classes.search_root}>
							
							<InputBase
								className={classes.input}
								placeholder="Search for tables or databases..."
								inputProps={{ 'aria-label': 'search' }}
								onChange={handleChange}
								onKeyDown={handleKeydown}
							/>
							<IconButton type="submit" className={classes.iconButton} aria-label="search" href={homeURL + "search_results/" + searchKey} disabled={searchKey===''}>
								<SearchIcon />
							</IconButton>
						</Paper>

					</Grid>
				</Grid>

			</Grid>
			<Grid item xs={12}>
				<Grid container justify="center" alignContent="center" spacing={0} className={classes.bottom}>
					<Grid item xs = {10}>
					<GridList cellHeight={350} cols={3}>
					<GridListTile key={"bigmap"}>
						<a href={homeURL + "bigmap"}>
							<img src="images/bigmap.png" className={classes.image}/>
						</a>
						<GridListTileBar
						title="Peer-to-Peer Discovery"
						/>
					</GridListTile>
					<GridListTile key={"datasets"}>
						
						<img src="images/book-book-pages-bookcase-browse-415071.jpg" className={classes.image}/>
						
						<GridListTileBar
						title="Browse the datasets"
						/>
					</GridListTile>
					<GridListTile key={"peerlearning"}>
						<a href={homeURL + 'projects'}>
						<img src="images/man-wearing-white-top-in-front-of-woman-wearing-blue-long-1181715.jpg" className={classes.image}/>
						</a>
						<GridListTileBar
						title="Browse Case Studies"
						/>
					</GridListTile>
					</GridList>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}
