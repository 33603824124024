import React from 'react';
import ReactMapGL, {Source, Layer, Popup, Marker, LinearInterpolator, WebMercatorViewport} from 'react-map-gl';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Head from './app_bar';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

import Button from '@material-ui/core/Button';

import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import List from '@material-ui/core/List';
import ListItem, {ListItemProps} from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


import '../css/mapbox_demo.css';
import '../css/mapbox-gl.css';
import CityPin from './pin';

import code2name from './code2name.json';
import closest_neighbors from './closest_neighbors_nationwide_alltracts.json';
import census_center from './census_center.json';

const TOKEN = 'pk.eyJ1IjoicnVveWFvdyIsImEiOiJjazZsbDAzMHcwMGdkM25tc25rbDg5eThnIn0.hTgR9KxwJvZF4Yb69uSLOw';

// City center coordinates for cities in the accessiblity data
const accs_centers = [[-81.80446784098534, 30.240466442831014], [-77.41649353817954, 38.86835779277303], [-75.30053360713525, 39.908016838606756], [-71.10483229991137, 42.55968157334404], [-80.73058025901351, 35.10784725995064], [-118.14863393950677, 34.222656199171205], [-116.73191950791244, 33.03646094929721], [-89.86260833176075, 35.02468277983159], [-77.52907037141628, 37.524437440268606], [-76.48266513450315, 36.906387293427755], [-86.68951115685691, 36.13807272607935], [-121.37459932831848, 36.908346453359144], [-121.84736119870483, 47.55657486044905], [-82.39790794559555, 28.159016521653243], [-81.34528812863034, 28.42852481340251], [-87.96130106647342, 41.70022592081393], [-122.16771048350495, 37.77534370624014], [-79.83074918312134, 40.43831372241504], [-116.12978514081763, 34.554917701234075], [-78.45851519692758, 35.75639385172899], [-121.01995678248717, 38.77507160734817], [-93.19764806916483, 45.06548121434434], [-80.49782220140898, 26.131839686804227], [-76.58666558813404, 39.34455131319505]]

// This seem is used to set the side column background color
const theme = createMuiTheme({
	palette: {
	  primary: {
		main: '#F3F3F3',
	  },
	},
});

// Unfortunately, the map that mapbox uses needs to show color and border of one census tract in two different layers in case you want to control them both.
// The commented part is another way to control the border line to show when mouse hover on a tract. 
// This method can be efficiently computed, but I can't find the interface in the react-map-gl package to do so.
const border_paint = {
    'line-color': '#6A2824',
    // 'line-width': ['case',
    //     ['boolean', ['feature-state', 'hover'], false],
    //     5,0
    // ]
    'line-width': 5.0
}

// Below defines the choropleth color of different maps in the big map.
const accs_census_choropleth_paint = {
    'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'w_c000_16'],
        10000000,
        '#fff7ec',
        20000000,
        '#fee8c8',
        30000000,
        '#fdd49e',
        40000000,
        '#fdbb84',
        50000000,
        '#fc8d59',
        60000000,
        '#ef6548',
        70000000,
        '#d7301f',
        80000000,
        '#b30000',
        90000000,
        '#7f0000',
        100000000,
        '#4b0000',
        ],
        'fill-opacity': 0.75
}

const LATCH_choropleth_paint = {
    'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'est_ptrp'],
        -1,
        '#000000',
        0,
        '#fff7ec',
        7,
        '#fee8c8',
        7.25,
        '#fdd49e',
        7.5,
        '#fdbb84',
        7.75,
        '#fc8d59',
        8,
        '#ef6548',
        8.25,
        '#d7301f',
        8.5,
        '#b30000',
        8.75,
        '#7f0000',
        9,
        '#4b0000',
        ],
    'fill-opacity': 0.75,
}

const FARS_county_choropleth_paint = {
    'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'normal'],
        0,
        '#fff7ec',
        0.1,
        '#fee8c8',
        0.2,
        '#fdd49e',
        0.3,
        '#fdbb84',
        0.4,
        '#fc8d59',
        0.5,
        '#ef6548',
        0.75,
        '#d7301f',
        1,
        '#b30000',
        2,
        '#7f0000',
        ],
        'fill-opacity': 0.75
}

const FARS_state_choropleth_paint = {
    'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'normal'],
        0,
        '#fff7ec',
        0.025,
        '#fee8c8',
        0.05,
        '#fdd49e',
        0.075,
        '#fdbb84',
        0.1,
        '#fc8d59',
        0.125,
        '#ef6548',
        0.15,
        '#d7301f',
        0.175,
        '#b30000',
        0.2,
        '#7f0000',
        ],
        'fill-opacity': 0.75
}

const os_choropleth_paint = {
    'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'traveltime'],
        0,
        '#fff7ec',
        0.1,
        '#fee8c8',
        0.2,
        '#fdd49e',
        0.3,
        '#fdbb84',
        0.4,
        '#fc8d59',
        0.5,
        '#ef6548',
        0.6,
        '#d7301f',
        0.7,
        '#b30000',
        0.8,
        '#7f0000',
        0.9,
        '#4b0000',
        ],
        'fill-opacity': 0.75
}

const pollution_census_choropleth_paint = {
    'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'normal'],
        0,
        '#fff7ec',
        0.1,
        '#fee8c8',
        0.2,
        '#fdd49e',
        0.5,
        '#fdbb84',
        1,
        '#fc8d59',
        2,
        '#ef6548',
        5,
        '#d7301f',
        10,
        '#b30000',
        20,
        '#7f0000',
        50,
        '#4b0000',
        ],
        'fill-opacity': 0.75
}

const pollution_county_choropleth_paint = {
    'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'normal'],
        0,
        '#fff7ec',
        0.1,
        '#fee8c8',
        0.2,
        '#fdd49e',
        0.3,
        '#fdbb84',
        0.4,
        '#fc8d59',
        0.5,
        '#ef6548',
        1,
        '#d7301f',
        2.5,
        '#b30000',
        5,
        '#7f0000',
        7.5,
        '#4b0000',
        ],
        'fill-opacity': 0.75
}

const pollution_state_choropleth_paint = {
    'fill-color': [
        'interpolate',
        ['linear'],
        ['get', 'normal'],
        0,
        '#fff7ec',
        0.05,
        '#fee8c8',
        0.1,
        '#fdd49e',
        0.15,
        '#fdbb84',
        0.2,
        '#fc8d59',
        0.25,
        '#ef6548',
        0.5,
        '#d7301f',
        0.75,
        '#b30000',
        1,
        '#7f0000',
        1.5,
        '#4b0000',
        ],
        'fill-opacity': 0.75
}

// State of the big map component
// viewport: map states like center lng/lat, zoom, ...
// selected_layer: the selected map, 0: accessibility, 1: LATCH, 2: FARS, 3: Opportunity score, 4: DARTE
// hoveredFeature: the feature of the tract/county/state that the mouse is hovered on
// clicked: whether there is a census tract being selected by clicking 
// mousePosition: the current mouse position on the map
// geoSearchKey: key entered by a user for geo search
// geoSearchPopperOpen: True if the geo search popper is open
// geoSearchOptions: geo search results
// geo: geo position of the search results. it is a bounding box if there is one, otherwise its a point coordinates
export interface State {
	viewport: any,
    selected_layer: any,
    filter: any;
    hoveredFeature: {"id":any, "feature": any, "percentile": any},
    clicked: boolean;
    mousePosition: any;
    geoSearchKey: string;
    geoSearchPopperOpen: boolean;
    geoSearchOptions: any;
    geo: any; // geo position of the search results. it is a bounding box if there is one, otherwise its a point coordinates
}

class Big_Map extends React.Component<State> {
	public state:State = {
		viewport: {
			latitude: 39.50,
			longitude: -98.35,
			zoom: 4,
			bearing: 0,
			pitch: 0,
			width: '100%',
			height: '100%',
		},
        selected_layer: '0',
        filter: ['in', 'id', ''],
        hoveredFeature: {"id": "", "feature": "", "percentile": ""},
        clicked: false,
        mousePosition: [],
        geoSearchKey: "",
        geoSearchPopperOpen: false,
        geoSearchOptions: <div/>,
        geo: []
	}

    // handles the event when a marker on the accessiblity map is clicked
    private markerClick = (position: any) => {
        console.log(position)
        this.setState({
            viewport: {
                ...this.state.viewport,
                longitude: position[0],
                latitude: position[1],
                zoom: 7,
                transitionDuration: 1000,
                transitionInterpolator: new LinearInterpolator()
            }
        });
        
        
    };

    // the recenter button onClick effect
    private onButtonClick = () => {
        this.setState({
            viewport: {
                ...this.state.viewport,
                latitude: 39.50,
                longitude: -98.35,
                zoom: 4,
            }
        })
    }

    // handles the changes of the layers selected
    private handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            selected_layer: (event.target as HTMLInputElement).value,
            hoveredFeature : {"id":"", "feature": "", "percentile":""},
            clicked: false,
        });
    }

    // handles the onClick effect of a census tract/county/state
    private onClick = (event:any) => {
        const clicked = this.state.clicked;
        if (this.state.hoveredFeature.id !== "") {
            this.setState({
                clicked: !clicked
            });
            if (clicked) {
                this.setState({
                    mousePosition: event.lngLat,
                    filter: ['in', 'id', ''],
                    hoveredFeature: {"id":"","feature":"", "percentile":""}
                })
            }
        }
    }

    // handle the on onHover effect of the whole map
	private onHover = (event:any) => {
        // froze the data displayed if a user clicks one region 
        if (!this.state.clicked){
            this.setState({mousePosition: event.lngLat})
            const {
                features,
                srcEvent: {offsetX, offsetY}
            } = event;
            // below are five different but similar code chunck that handles the logic of the onHover effect of five different maps
            if (this.state.selected_layer==='0'){
                const accs_census_features = features && features.find(f => f.layer.id === 'accs_census');
                if (accs_census_features) {
                    let id = accs_census_features["properties"]['GEOID']
                    this.setState({filter: ['==', 'GEOID', id],}) // seems that split this state out benefit the performance
                    this.setState({                    
                        hoveredFeature: {"id":id, "feature": accs_census_features["properties"]['w_c000_16'], "percentile":accs_census_features["properties"]["percentile"]},
                    });
                }
                else{
                    this.setState({filter: ['==', 'id', '']})
                    this.setState({
                        hoveredFeature: {"id":"","feature":"", "percentile":""}
                    })
                }
            }
            if (this.state.selected_layer==='1'){

                const latch_census_features = features && features.find(f => f.layer.id === 'latch_census');
                const latch_county_features = features && features.find(f => f.layer.id === 'latch_county');
                const latch_state_features = features && features.find(f => f.layer.id === 'latch_state');
                if (latch_census_features) {
                    let id = latch_census_features["properties"]['GEOID']
                    this.setState({filter: ['==', 'GEOID', id],}) // seems that split this state out benefit the performance

                    this.setState({                    
                        hoveredFeature: {"id":id, "feature": latch_census_features["properties"]['est_ptrp'], "percentile":latch_census_features["properties"]["percentile"]},
                    });
                }
                else if (latch_county_features) {
                    let id = latch_county_features["properties"]['COUNTYID'];
                    this.setState({filter: ['==', 'COUNTYID', id],})
                    this.setState({                    
                        hoveredFeature: {"id":id, "feature": latch_county_features["properties"]['est_ptrp'], "percentile":latch_county_features["properties"]["percentile"]},
                    });
                }
                else if (latch_state_features) {
                    let id = latch_state_features["properties"]['STATEID'];
                    this.setState({filter: ['==', 'STATEID', id],})
                    this.setState({                    
                        hoveredFeature: {"id":id, "feature": latch_state_features["properties"]['est_ptrp'], "percentile":latch_state_features["properties"]["percentile"]},
                    });
                }
                else{
                    this.setState({filter: ['==', 'id', '']})
                    this.setState({
                        hoveredFeature: {"id":"","feature":"", "percentile":""}
                    })
                }
            }

            if (this.state.selected_layer==='2'){
                const fars_county_features = features && features.find(f => f.layer.id === 'fars_county');
                const fars_state_features = features && features.find(f => f.layer.id === 'fars_state');

                if (fars_county_features) {
                    let id = fars_county_features["properties"]['COUNTY'];
                    this.setState({filter: ['==', 'COUNTY', id],})
                    this.setState({                    
                        hoveredFeature: {"id":id, "feature": fars_county_features["properties"]['normal'], "percentile":""},
                    });
                }
                else if (fars_state_features) {
                    let id = fars_state_features["properties"]['STATE'];
                    this.setState({filter: ['==', 'STATE', id],})
                    this.setState({                    
                        hoveredFeature: {"id":id, "feature": fars_state_features["properties"]['normal'], "percentile":""},
                    });
                }
                else{
                    this.setState({filter: ['==', 'id', '']})
                    this.setState({
                        hoveredFeature: {"id":"","feature":"", "percentile":""}
                    })
                }
            }

            if (this.state.selected_layer==='3'){

                const os_census_features = features && features.find(f => f.layer.id === 'os_census');
                const os_county_features = features && features.find(f => f.layer.id === 'os_county');
                const os_state_features = features && features.find(f => f.layer.id === 'os_state');
                if (os_census_features) {
                    let id = os_census_features["properties"]['GEOID']
                    this.setState({filter: ['==', 'GEOID', id],}) // seems that split this state out benefit the performance

                    this.setState({                    
                        hoveredFeature: {"id":id, "feature": os_census_features["properties"]['traveltime'], "percentile":""},
                    });
                }
                else if (os_county_features) {
                    let id = os_county_features["properties"]['COUNTYID'];
                    this.setState({filter: ['==', 'COUNTYID', id],})
                    this.setState({                    
                        hoveredFeature: {"id":id, "feature": os_county_features["properties"]['traveltime'], "percentile":""},
                    });
                }
                else if (os_state_features) {
                    let id = os_state_features["properties"]['STATEID'];
                    this.setState({filter: ['==', 'STATEID', id],})
                    this.setState({                    
                        hoveredFeature: {"id":id, "feature": os_state_features["properties"]['traveltime'], "percentile":""},
                    });
                }
                else{
                    this.setState({filter: ['==', 'id', '']})
                    this.setState({
                        hoveredFeature: {"id":"","feature":"", "percentile":""}
                    })
                }
            }

            if (this.state.selected_layer==='4'){

                const pollution_census_features = features && features.find(f => f.layer.id === 'pollution_census');
                const pollution_county_features = features && features.find(f => f.layer.id === 'pollution_county');
                const pollution_state_features = features && features.find(f => f.layer.id === 'pollution_state');
                if (pollution_census_features) {
                    let id = pollution_census_features["properties"]['GEOID_CENS']
                    this.setState({filter: ['==', 'GEOID_CENS', id],}) // seems that split this state out benefit the performance

                    this.setState({                    
                        hoveredFeature: {"id":id, "feature": pollution_census_features["properties"]['normal'], "percentile":""},
                    });
                }
                else if (pollution_county_features) {
                    let id = pollution_county_features["properties"]['COUNTYID'];
                    this.setState({filter: ['==', 'COUNTYID', id],})
                    this.setState({                    
                        hoveredFeature: {"id":id, "feature": pollution_county_features["properties"]['normal'], "percentile":""},
                    });
                }
                else if (pollution_state_features) {
                    let id = pollution_state_features["properties"]['STATEID'];
                    this.setState({filter: ['==', 'STATEID', id],})
                    this.setState({                    
                        hoveredFeature: {"id":id, "feature": pollution_state_features["properties"]['normal'], "percentile":""},
                    });
                }
                else{
                    this.setState({filter: ['==', 'id', '']})
                    this.setState({
                        hoveredFeature: {"id":"","feature":"", "percentile":""}
                    })
                }
            }
        }
    }

    // handles the changes of the map viewport.
    // Remove the width and height of the previous viewport so that the map can fit to a different container size.
    private onViewPortChange = (viewport) => {
        const {width, height, ...etc} = viewport
        this.setState({viewport: etc})
    }

    // render the popup info box on the map
    private renderPopupContents = () => {
        // state id
        if (this.state.hoveredFeature.id.length === 2){
            return <div>{code2name[this.state.hoveredFeature.id][1]}</div>
        }
        // county id
        else if (this.state.hoveredFeature.id.length === 5) {
            return <div>{code2name[this.state.hoveredFeature.id][0]},{code2name[this.state.hoveredFeature.id][1]}</div>
        }
        // census tract
        else {
            let county_id = this.state.hoveredFeature.id.slice(0,5);
            let tract_id = this.state.hoveredFeature.id.slice(5);
            return (
                <div>
                    <div>{code2name[county_id][0]}, {code2name[county_id][1]}</div>
                    <div>Tract {tract_id} </div>
                </div>
            )
        }                               
    }

    // renders some geometry info in the left column
    private renderGeoInfo = () => {
        if (this.state.hoveredFeature.id){
            if (this.state.hoveredFeature.id.length === 2){
                return <Typography variant='subtitle1' color='textPrimary'>{code2name[this.state.hoveredFeature.id][1]}</Typography>
            }

            else if (this.state.hoveredFeature.id.length === 5) {
                return <Typography variant='subtitle1' color='textPrimary'>{code2name[this.state.hoveredFeature.id][0]},{code2name[this.state.hoveredFeature.id][1]}</Typography>
            }

            else {
                let county_id = this.state.hoveredFeature.id.slice(0,5);
                let tract_id = this.state.hoveredFeature.id.slice(5);
                return (
                    <div>
                        <Typography variant='subtitle1' color='textPrimary'>{code2name[county_id][0]}, {code2name[county_id][1]}</Typography>
                        <Typography variant='subtitle1' color='textPrimary'>Tract {tract_id} </Typography>
                    </div>
                )
            }
        }                               
    }

    // base url for geo search
    private baseURL = "https://api.mapbox.com/geocoding/v5/mapbox.places/"

    // Input the data returned from the geo search api
    // Returns the html elements to display the data
    private setGeoSearchResults(data) {
        if (data.features[0].bbox !== undefined){
            this.setState({
                geo: data.features[0].bbox
            })
        }
        else{
            this.setState({
                geo: data.features[0].center
            })
        }
        
        return(
        <List>
            {data.features.map((result: any) => {
                return(
                <ListItem button={true} onClick={this.handleSearchResultClick.bind(this, result)}>
                    <ListItemText primary={result.place_name}/>
                </ListItem>
                )
            })
            }
        </List>
        )
    }
    
    // When a user clicks on one of the geosearch result
    // Recenter and zoom to the target position
    private handleSearchResultClick = (result: any) => {
        if (result.bbox !== undefined){
            this.setState({
                geoSearchKey: result.place_name,
                geo: result.bbox,
                geoSearchPopperOpen: false
            })
            this.locate(result.bbox)
        }
        else{
            this.setState({
                geoSearchKey: result.place_name,
                geo: result.center,
                geoSearchPopperOpen: false
            })
            this.locate(result.center)
        }
    }

    // Handles the changes of the geo search key (user input)
    private handleSearchChange = (event) => {
        this.setState({geoSearchKey:event.target.value});
	    if (event.target.value != ''){
			const url = this.baseURL + event.target.value + ".json?access_token=" + TOKEN;
			console.log(url);
			fetch(url)
				.then(response=>response.json())
				.then((jsonData) => {
				if (jsonData.features.length !== 0 ){
					this.setState({
                        geoSearchOptions: this.setGeoSearchResults(jsonData),
                        geoSearchPopperOpen: true
                    })
				}
				else{
					this.setState({
                        geoSearchOptions: <div/>,
                        geoSearchPopperOpen: false
                    })
				}
			})
		}
		else{
			this.setState({
                geoSearchOptions: <div/>,
                geoSearchPopperOpen: false
            })
		}
	};
    
    // compute the position of the geo search result
    private locate(bbox) {
        let longitude, latitude, zoom;
        if (bbox.length === 4) {
            const minLng = bbox[0]
            const minLat = bbox[1]
            const maxLng = bbox[2]
            const maxLat = bbox[3]
            const height = document.getElementById("container")?.clientHeight;
            const width = document.getElementById("container")?.clientWidth;
            const viewport = new WebMercatorViewport({height: height, width: width});
            let view_port_args = viewport.fitBounds([[minLng, minLat], [maxLng, maxLat]], {
                padding: 40
            });
            longitude = view_port_args.longitude
            latitude = view_port_args.latitude
            zoom = view_port_args.zoom
        }
        else if (bbox.length === 2){
            longitude = bbox[0]
            latitude =bbox[1]
            zoom = 12
        }
        this.setState({
            viewport:{
                ...this.state.viewport,
                latitude: latitude,
                longitude: longitude,
                zoom: zoom,
            },
        })
    }

  	render() {
    return (
        
        <div>
                <ThemeProvider theme = {theme}>
                    <Box bgcolor="primary.main" color="primary.contrastText" className="box"></Box>
                </ThemeProvider>
                <Grid container >
                    <Grid item xs={12}>
                        <Head containSearch={true}/>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0} alignItems='stretch' alignContent='center'>
                            <Grid item xs={3} className='left_column'>
                                <Paper className='geoSearchContainer'>
                                    <div>
                                        <Popper className="geoSearchPopper" open={this.state.geoSearchPopperOpen} anchorEl={document.getElementById('geosearch_input')} placement={'bottom-start'} transition>
                                            {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} timeout={350}>
                                                <Paper>
                                                    {this.state.geoSearchOptions}
                                                </Paper>
                                            </Fade>
                                            )}
                                        </Popper>
                                        </div>
                                        <div  id='geosearch_input'>        
                                            <IconButton type="submit"  aria-label="search" disabled={this.state.geoSearchKey===''}>
                                                <SearchIcon onClick={this.locate.bind(this, this.state.geo)}/>
                                            </IconButton>
                                            <InputBase
                                                placeholder="Search for address or place…"
                                                inputProps={{ 'aria-label': 'search' }}
                                                onChange={this.handleSearchChange}
                                                value = {this.state.geoSearchKey}
                                                className = "geoSearchInput"
                                        />
                                    </div>
                                </Paper>


                                <Paper className='layer_control'>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Choose one layer to display:</FormLabel>
                                        <RadioGroup aria-label="layers" name="layers1" value={this.state.selected_layer} onChange={this.handleChange}>
                                        <FormControlLabel value={"0"} control={<Radio color='primary'/>}  label={<div>Accessbility <Tooltip title='Accessiblity' placement='right-end'><HelpIcon style={{fontSize: 15}}/></Tooltip></div>} />
                                        <FormControlLabel value={"1"} control={<Radio color='primary'/>}  label={<div>Travel Demand <Tooltip title='Travel Demand' placement='right-end'><HelpIcon style={{fontSize: 15}}/></Tooltip></div>} />
                                        <FormControlLabel value={"2"} control={<Radio color='primary'/>}  label={<div>Fatal Crash Risk <Tooltip title='Fatal Crash Risk' placement='right-end'><HelpIcon style={{fontSize: 15}}/></Tooltip></div>} />
                                        <FormControlLabel value={"3"} control={<Radio color='primary'/>}  label={<div>Opportunity Score <Tooltip title='Opportunity Score' placement='right-end'><HelpIcon style={{fontSize: 15}}/></Tooltip></div>} />
                                        <FormControlLabel value={"4"} control={<Radio color='primary'/>}  label={<div>Annual On-road CO<sub>2</sub> Emission <Tooltip title='Annual On-road CO2 Emission' placement='right-end'><HelpIcon style={{fontSize: 15}}/></Tooltip></div>} />
                                        </RadioGroup>
                                    </FormControl>
                                </Paper>


                                {
                                    this.state.hoveredFeature &&                               
                                    this.state.selected_layer=='0' && 
                                    <div className="info">
                                        <Typography variant='h6' color='textPrimary'><b>Accessibility</b></Typography>
                                        <Typography variant='subtitle2' color='textSecondary'>These data were created as part of a study at the University of Minnesota that examined the accessibility to jobs by transit in the 50 largest (by population) metropolitan areas in the United States.</Typography>
                                        <br/>                                    
                                        <Typography variant='subtitle1' color='textPrimary'><b>Accessibility Data</b></Typography>
                                        {this.renderGeoInfo()}                                    
                                        <Typography variant='subtitle2'>ID</Typography>
                                        <Typography variant='subtitle2' color='textSecondary' className="text">{this.state.hoveredFeature.id!==""?this.state.hoveredFeature.id:<br/>}</Typography>
                                        <Typography variant='subtitle2'>Number of jobs within 30 min drive</Typography>
                                        <Typography variant='subtitle2' color='textSecondary' className="text"> {this.state.hoveredFeature.feature!==""?this.state.hoveredFeature.feature:<br/>}</Typography>
                                        <br/>
                                        <div className="percentile">
                                            <Typography variant='subtitle2' color='textSecondary' className='lowest'>Lowest</Typography>
                                            <Typography variant='subtitle2' color='textSecondary' className='highest'>Highest</Typography>
                                            <div className="percentile_palette">
                                                <div className="label-range">
                                                {this.state.hoveredFeature.percentile !== "" &&
                                                    <span className="label_icon" style={{left:this.state.hoveredFeature.percentile}}/>
                                                }
                                                </div>                                        
                                            </div>
                                            { this.state.hoveredFeature.percentile !== "" &&
                                                <div className='number-range'>
                                                <Typography variant='subtitle2' style={{position:"relative",left: this.state.hoveredFeature.percentile}}>
                                                    {this.state.hoveredFeature.percentile}
                                                </Typography>
                                                </div>
                                            }
                                        </div>
                                        {this.state.hoveredFeature.id !== "" && this.state.hoveredFeature.id.length === 11 && this.state.hoveredFeature.id in closest_neighbors &&
                                            <div className = 'neighborsContainer'>
                                                <Typography variant='subtitle2'>Related Census Tracts</Typography>
                                                {
                                                    // Here in the onClick function, the filter state change will have not effects.
                                                    // The ideal state change would be set the clicked still be true, displaying data stored in hoveredFeature
                                                    // However, in current archetecture, it is difficult to get the data of one specific tract given its id
                                                    // So I set clicked to false to keep the map still working and leave the filter changed
                                                    closest_neighbors[this.state.hoveredFeature.id].map( (neighbor_id: any) => {
                                                        let county_id = neighbor_id.slice(0,5);
                                                        let tract_id = neighbor_id.slice(5);
                                                    return <div className='neighbors' onClick={ ()=>
                                                        this.setState({
                                                        viewport:{
                                                            ...this.state.viewport,
                                                            latitude: census_center[neighbor_id][1],
                                                            longitude: census_center[neighbor_id][0],
                                                            zoom: 10
                                                        },
                                                        filter: ['in', 'id', neighbor_id],
                                                        hoveredFeature: {"id":"","feature":"", "percentile":""},
                                                        clicked: false
                                                    })}><p className="neighborsText"><span className="neighborsTextHighlight">Tract {tract_id}</span> {code2name[county_id][0]}, {code2name[county_id][1]}</p></div>
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    this.state.hoveredFeature &&                               
                                    this.state.selected_layer=='1' && 
                                    <div className="info">
                                        <Typography variant='h6' color='textPrimary'><b>Travel Demand</b></Typography>
                                        <Typography variant='subtitle2' color='textSecondary'>The Bureau of Transportation Statistics (BTS) developed Local Area Transportation Characteristics for Households (LATCH Survey) that allows estimation of the average weekday household person trips, vehicle trips, person miles traveled, and vehicle miles traveled (per day), for all Census tracts in the United States.  LATCH combines the National Household Travel Survey (NHTS) data along with American Community Survey (ACS) data from the Census Bureau. </Typography>
                                        <br/>
                                        
                                        <Typography variant='subtitle1' color='textPrimary'><b>Travel Demand Data</b></Typography>
                                        {this.renderGeoInfo()}
                                        <Typography variant='subtitle2'>ID</Typography>
                                        <Typography variant='subtitle2' color='textSecondary' className="text">{this.state.hoveredFeature.id!==""?this.state.hoveredFeature.id:<br/>}</Typography>
                                        <Typography variant='subtitle2'>Estimated annual trips per person</Typography>
                                        <Typography variant='subtitle2' color='textSecondary' className="text"> {this.state.hoveredFeature.feature!==""?this.state.hoveredFeature.feature:<br/>}</Typography>
                                        <br/>
                                        <div className="percentile">
                                            <Typography variant='subtitle2' color='textSecondary' className='lowest'>Lowest</Typography>
                                            <Typography variant='subtitle2' color='textSecondary' className='highest'>Highest</Typography>
                                            <div className="percentile_palette">
                                                <div className="label-range">
                                                {this.state.hoveredFeature.percentile !== "" &&
                                                    <span className="label_icon" style={{left:this.state.hoveredFeature.percentile}}/>
                                                }
                                                </div>                                        
                                            </div>
                                            { this.state.hoveredFeature.percentile !== "" &&
                                                <div className='number-range'>
                                                <Typography variant='subtitle2' style={{position:"relative",left: this.state.hoveredFeature.percentile}}>
                                                    {this.state.hoveredFeature.percentile}
                                                </Typography>
                                                </div>
                                            }
                                        </div>
                                        {this.state.hoveredFeature.id !== "" && this.state.hoveredFeature.id.length === 11 && this.state.hoveredFeature.id in closest_neighbors &&
                                            <div className = 'neighborsContainer'>
                                                <Typography variant='subtitle2'>Related Census Tracts</Typography>
                                                {
                                                    closest_neighbors[this.state.hoveredFeature.id].map( (neighbor_id: any) => {
                                                        let county_id = neighbor_id.slice(0,5);
                                                        let tract_id = neighbor_id.slice(5);
                                                    return <div className='neighbors' onClick={ ()=>
                                                        this.setState({
                                                        viewport:{
                                                            ...this.state.viewport,
                                                            latitude: census_center[neighbor_id][1],
                                                            longitude: census_center[neighbor_id][0],
                                                            zoom: 10
                                                        },
                                                        filter: ['in', 'id', neighbor_id],
                                                        hoveredFeature: {"id":"","feature":"", "percentile":""},
                                                        clicked: false
                                                    })}><p className="neighborsText"><span className="neighborsTextHighlight">Tract {tract_id}</span> {code2name[county_id][0]}, {code2name[county_id][1]}</p></div>
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>
                                }
                                {
                                    this.state.hoveredFeature &&                               
                                    this.state.selected_layer=='2' && 
                                    <div className="info">
                                        <Typography variant='h6' color='textPrimary'><b>Fatal Crash Risk</b></Typography>
                                        <Typography variant='subtitle2' color='textSecondary'>Fatal Analysis Reporting System (FARS) is a nationwide census providing National Highway Traffic Safety Administration (NHTSA), Congress and the American public yearly data regarding fatal injuries suffered in motor vehicle traffic crashes.</Typography>
                                        <br/>
                                        
                                        <Typography variant='subtitle1' color='textPrimary'><b>Fatal Crash Risk Data</b></Typography>
                                        {this.renderGeoInfo()}
                                        <Typography variant='subtitle2'>ID</Typography>
                                        <Typography variant='subtitle2' color='textSecondary' className="text">{this.state.hoveredFeature.id!==""?this.state.hoveredFeature.id:<br/>}</Typography>
                                        <Typography variant='subtitle2'>Fatals in traffic accidents per 1000 people</Typography>
                                        <Typography variant='subtitle2' color='textSecondary' className="text"> {this.state.hoveredFeature.feature!==""?this.state.hoveredFeature.feature:<br/>}</Typography>
                                        {this.state.hoveredFeature.id !== "" && this.state.hoveredFeature.id.length === 11 && this.state.hoveredFeature.id in closest_neighbors &&
                                            <div className = 'neighborsContainer'>
                                                <Typography variant='subtitle2'>Related Census Tracts</Typography>
                                                {
                                                    closest_neighbors[this.state.hoveredFeature.id].map( (neighbor_id: any) => {
                                                        let county_id = neighbor_id.slice(0,5);
                                                        let tract_id = neighbor_id.slice(5);
                                                    return <div className='neighbors' onClick={ ()=>
                                                        this.setState({
                                                        viewport:{
                                                            ...this.state.viewport,
                                                            latitude: census_center[neighbor_id][1],
                                                            longitude: census_center[neighbor_id][0],
                                                            zoom: 10
                                                        },
                                                        filter: ['in', 'id', neighbor_id],
                                                        hoveredFeature: {"id":"","feature":"", "percentile":""},
                                                        clicked: false
                                                    })}><p className="neighborsText"><span className="neighborsTextHighlight">Tract {tract_id}</span> {code2name[county_id][0]}, {code2name[county_id][1]}</p></div>
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>                                    
                                }
                                {
                                    this.state.hoveredFeature &&                               
                                    this.state.selected_layer=='3' && 
                                    <div className="info">
                                        <Typography variant='h6' color='textPrimary'><b>Opportunity Score</b></Typography>
                                        <Typography variant='subtitle2' color='textSecondary'>For each tract, the Opportunity score estimates children’s outcomes in adulthood such as earnings distributions and incarceration rates by parental income, race, and gender.</Typography>
                                        <br/>
                                        
                                        <Typography variant='subtitle1' color='textPrimary'><b>Opportunity Score Data</b></Typography>
                                        {this.renderGeoInfo()}
                                        <Typography variant='subtitle2'>ID</Typography>
                                        <Typography variant='subtitle2' color='textSecondary' className="text">{this.state.hoveredFeature.id!==""?this.state.hoveredFeature.id:<br/>}</Typography>
                                        <Typography variant='subtitle2'>Percentage of people who can get to job within 15 min</Typography>
                                        <Typography variant='subtitle2' color='textSecondary' className="text">{this.state.hoveredFeature.feature!==""?this.state.hoveredFeature.feature:<br/>}</Typography>                                
                                        {this.state.hoveredFeature.id !== "" && this.state.hoveredFeature.id.length === 11 && this.state.hoveredFeature.id in closest_neighbors &&
                                            <div className = 'neighborsContainer'>
                                                <Typography variant='subtitle2'>Related Census Tracts</Typography>
                                                {
                                                    closest_neighbors[this.state.hoveredFeature.id].map( (neighbor_id: any) => {
                                                        let county_id = neighbor_id.slice(0,5);
                                                        let tract_id = neighbor_id.slice(5);
                                                    return <div className='neighbors' onClick={ ()=>
                                                        this.setState({
                                                        viewport:{
                                                            ...this.state.viewport,
                                                            latitude: census_center[neighbor_id][1],
                                                            longitude: census_center[neighbor_id][0],
                                                            zoom: 10
                                                        },
                                                        filter: ['in', 'id', neighbor_id],
                                                        hoveredFeature: {"id":"","feature":"", "percentile":""},
                                                        clicked: false
                                                    })}><p className="neighborsText"><span className="neighborsTextHighlight">Tract {tract_id}</span> {code2name[county_id][0]}, {code2name[county_id][1]}</p></div>
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>

                                }
                                {
                                    this.state.hoveredFeature &&                               
                                    this.state.selected_layer=='4' && 
                                    <div className="info">
                                        <Typography variant='h6' color='textPrimary'><b>Annual On-road CO<sub>2</sub> Emission</b></Typography>
                                        <Typography variant='subtitle2' color='textSecondary'>DARTE provides a 38-year, 1-km resolution inventory of annual on-road CO2 emissions for the conterminous United States based on roadway-level vehicle traffic data and state-specific emissions factors for multiple vehicle types on urban and rural roads as compiled in the Database of Road Transportation Emissions (DARTE).</Typography>
                                        <br/>
                                        
                                        <Typography variant='subtitle2' color='textPrimary'><b>Annual On-road CO<sub>2</sub> Emission Data</b></Typography>
                                        {this.renderGeoInfo()}
                                        <Typography variant='subtitle2'>ID</Typography>
                                        <Typography variant='subtitle2' color='textSecondary' className="text">{this.state.hoveredFeature.id!==""?this.state.hoveredFeature.id:<br/>}</Typography>
                                        <Typography variant='subtitle2'>CO<sub>2</sub> emission in 2017 (Kg/m<sup>2</sup>)</Typography>
                                        <Typography variant='subtitle2' color='textSecondary' className="text">{this.state.hoveredFeature.feature!==""?this.state.hoveredFeature.feature:<br/>}</Typography>                                
                                        {this.state.hoveredFeature.id !== "" && this.state.hoveredFeature.id.length === 11 && this.state.hoveredFeature.id in closest_neighbors &&
                                            <div className = 'neighborsContainer'>
                                                <Typography variant='subtitle2'>Related Census Tracts</Typography>
                                                {
                                                    closest_neighbors[this.state.hoveredFeature.id].map( (neighbor_id: any) => {
                                                        let county_id = neighbor_id.slice(0,5);
                                                        let tract_id = neighbor_id.slice(5);
                                                    return <div className='neighbors' onClick={ ()=>
                                                        this.setState({
                                                        viewport:{
                                                            ...this.state.viewport,
                                                            latitude: census_center[neighbor_id][1],
                                                            longitude: census_center[neighbor_id][0],
                                                            zoom: 10
                                                        },
                                                        filter: ['in', 'id', neighbor_id],
                                                        hoveredFeature: {"id":"","feature":"", "percentile":""},
                                                        clicked: false
                                                    })}><p className="neighborsText"><span className="neighborsTextHighlight">Tract {tract_id}</span> {code2name[county_id][0]}, {code2name[county_id][1]}</p></div>
                                                    })
                                                }
                                            </div>
                                        }
                                    </div>

                                }
                            </Grid>

                            <Grid item xs={9}>                            

                            <div className='recenterButton'>
                                <Button variant="contained" onClick={this.onButtonClick}>Recenter</Button>
                            </div>
                                <div id="container" className="mapContainer">                                
                                <ReactMapGL
                                    {...this.state.viewport}
                                    mapStyle="mapbox://styles/mapbox/light-v10"
                                    mapboxApiAccessToken={TOKEN}
                                    width="100%"
                                    height="100%"
                                    onViewportChange={this.onViewPortChange}
                                    onHover = {this.onHover}
                                    onClick = {this.onClick}
                                    minZoom = {3}
                                >
                                    {
                                        this.state.mousePosition.length > 0 && this.state.hoveredFeature.id  &&
                                        <Popup 
                                            latitude={this.state.mousePosition[1]}
                                            longitude={this.state.mousePosition[0]}
                                            closeButton={false}
                                        >
                                            {this.renderPopupContents()}
                                            <div>{this.state.hoveredFeature.feature}</div>
                                        </Popup>
                                    }
                                    {/* Accessibility */}
                                    {this.state.selected_layer === '0' &&
                                    <div>
                                    <Source id="accs_census_data" type="vector" url='mapbox://ruoyaow.45rc6mpg'>
                                        <Layer
                                            id="accs_census_borders"
                                            type="line"
                                            source="accs_census_data"
                                            source-layer="accs_census"
                                            minzoom={7}
                                            paint = {border_paint}
                                            filter = {this.state.filter}
                                            beforeId = "waterway-label"
                                        />
                                        <Layer
                                            id="accs_census"
                                            type="fill"
                                            source="accs_census_data"
                                            source-layer="accs_census"
                                            minzoom={7}
                                            on
                                            paint = {accs_census_choropleth_paint}
                                            beforeId = "accs_census_borders"
                                        />                			
                                    </Source>
                                    {   
                                        this.state.viewport.zoom<7 && accs_centers.map((position)=>{
                                        return (
                                            <Marker longitude={position[0]} latitude={position[1]} anchor="bottom" onClick={this.markerClick.bind(this, position)}>
                                                <CityPin size={20} onClick={this.markerClick.bind(this, position)} />
                                            </Marker>
                                        )
                                    })
                                                                
                                    }	
                                    </div>
                                    } 
                                    {/* LATCH */}
                                    {this.state.selected_layer === '1' &&
                                    <div>
                                    <Source id="latch_census_data" type="vector" url='mapbox://ruoyaow.dcgc1ueu'>
                                        <Layer
                                            id="latch_census_borders"
                                            type="line"
                                            source="latch_census_data"
                                            source-layer="latch_census-bs1np6"
                                            minzoom={7}
                                            paint = {border_paint}
                                            filter = {this.state.filter}
                                            beforeId = "waterway-label"
                                        />
                                        <Layer
                                            id="latch_census"
                                            type="fill"
                                            source="latch_census_data"
                                            source-layer="latch_census-bs1np6"
                                            minzoom={7}
                                            on
                                            paint = {LATCH_choropleth_paint}
                                            beforeId = "latch_census_borders"
                                        />				
                                    </Source>

                                    <Source id="latch_county_data" type="vector" url='mapbox://ruoyaow.2or4h2x8'>
                                        <Layer
                                            id="latch_county_borders"
                                            type="line"
                                            source="latch_county_data"
                                            source-layer="latch_county-93infz"
                                            maxzoom={7}
                                            minzoom={5}
                                            paint = {border_paint}
                                            filter = {this.state.filter}
                                            beforeId = "waterway-label"
                                        />
                                        <Layer
                                            id="latch_county"
                                            type="fill"
                                            source="latch_county_data"
                                            source-layer="latch_county-93infz"
                                            maxzoom={7}
                                            minzoom={5}
                                            paint = {LATCH_choropleth_paint}
                                            beforeId = "latch_county_borders"
                                        />				
                                    </Source>

                                    <Source id="latch_state_data" type="vector" url='mapbox://ruoyaow.2ifej5es'>
                                        <Layer
                                            id="latch_state_borders"
                                            type="line"
                                            source="latch_state_data"
                                            source-layer="latch_state-75zdos"
                                            maxzoom={5}
                                            paint = {border_paint}
                                            filter = {this.state.filter}
                                            beforeId = "waterway-label"
                                        />
                                        <Layer
                                            id="latch_state"
                                            type="fill"
                                            source="latch_state_data"
                                            source-layer="latch_state-75zdos"
                                            maxzoom={5}
                                            paint = {LATCH_choropleth_paint}
                                            beforeId = "latch_state_borders"
                                        />				
                                    </Source>
                                    </div>
                                    } 

                                    {/* FARS */}
                                    {this.state.selected_layer === '2' &&
                                    <div>

                                    <Source id="fars_county_data" type="vector" url='mapbox://ruoyaow.5ebn0k3w'>
                                        <Layer
                                            id="fars_county_borders"
                                            type="line"
                                            source="fars_county_data"
                                            source-layer="fars_county"
                                            minzoom={5}
                                            paint = {border_paint}
                                            filter = {this.state.filter}
                                            beforeId = "waterway-label"
                                        />
                                        <Layer
                                            id="fars_county"
                                            type="fill"
                                            source="fars_county_data"
                                            source-layer="fars_county"
                                            minzoom={5}
                                            paint = {FARS_county_choropleth_paint}
                                            beforeId = "fars_county_borders"
                                        />				
                                    </Source>

                                    <Source id="fars_state_data" type="vector" url='mapbox://ruoyaow.2exguf5b'>
                                        <Layer
                                            id="fars_state_borders"
                                            type="line"
                                            source="fars_state_data"
                                            source-layer="fars_state-bbckew"
                                            maxzoom={5}
                                            paint = {border_paint}
                                            filter = {this.state.filter}
                                            beforeId = "waterway-label"
                                        />
                                        <Layer
                                            id="fars_state"
                                            type="fill"
                                            source="fars_state_data"
                                            source-layer="fars_state-bbckew"
                                            maxzoom={5}
                                            paint = {FARS_state_choropleth_paint}
                                            beforeId = "fars_state_borders"
                                        />				
                                    </Source>
                                    </div>
                                    }

                                    {/* Opportunity Score */}
                                    {this.state.selected_layer === '3' &&
                                    <div>
                                    <Source id="os_census_data" type="vector" url='mapbox://ruoyaow.71a45625'>
                                        <Layer
                                            id="os_census_borders"
                                            type="line"
                                            source="os_census_data"
                                            source-layer="country_data_new-5neb1o"
                                            minzoom={7}
                                            paint = {border_paint}
                                            filter = {this.state.filter}
                                            beforeId = "waterway-label"
                                        />
                                        <Layer
                                            id="os_census"
                                            type="fill"
                                            source="os_census_data"
                                            source-layer="country_data_new-5neb1o"
                                            minzoom={7}
                                            on
                                            paint = {os_choropleth_paint}
                                            beforeId = "os_census_borders"
                                        />				
                                    </Source>

                                    <Source id="os_county_data" type="vector" url='mapbox://ruoyaow.b1coiy9s'>
                                        <Layer
                                            id="os_county_borders"
                                            type="line"
                                            source="os_county_data"
                                            source-layer="opportunity_score_county-1wl3dn"
                                            maxzoom={7}
                                            minzoom={5}
                                            paint = {border_paint}
                                            filter = {this.state.filter}
                                            beforeId = "waterway-label"
                                        />
                                        <Layer
                                            id="os_county"
                                            type="fill"
                                            source="os_county_data"
                                            source-layer="opportunity_score_county-1wl3dn"
                                            maxzoom={7}
                                            minzoom={5}
                                            paint = {os_choropleth_paint}
                                            beforeId = "os_county_borders"
                                        />				
                                    </Source>

                                    <Source id="os_state_data" type="vector" url='mapbox://ruoyaow.2dt86nll'>
                                        <Layer
                                            id="os_state_borders"
                                            type="line"
                                            source="os_state_data"
                                            source-layer="os_state-cfmj0t"
                                            maxzoom={5}
                                            paint = {border_paint}
                                            filter = {this.state.filter}
                                            beforeId = "waterway-label"
                                        />
                                        <Layer
                                            id="os_state"
                                            type="fill"
                                            source="os_state_data"
                                            source-layer="os_state-cfmj0t"
                                            maxzoom={5}
                                            paint = {os_choropleth_paint}
                                            beforeId = "os_state_borders"
                                        />				
                                    </Source>
                                    </div>
                                    }

                                    {/* Pollution */}
                                    {this.state.selected_layer === '4' &&
                                    <div>
                                    <Source id="pollution_census_data" type="vector" url='mapbox://ruoyaow.70of12re'>
                                        <Layer
                                            id="pollution_census_borders"
                                            type="line"
                                            source="pollution_census_data"
                                            source-layer="pollution_census"
                                            minzoom={7}
                                            paint = {border_paint}
                                            filter = {this.state.filter}
                                            beforeId = "waterway-label"
                                        />
                                        <Layer
                                            id="pollution_census"
                                            type="fill"
                                            source="pollution_census_data"
                                            source-layer="pollution_census"
                                            minzoom={7}
                                            on
                                            paint = {pollution_census_choropleth_paint}
                                            beforeId = "pollution_census_borders"
                                        />				
                                    </Source>

                                    <Source id="pollution_county_data" type="vector" url='mapbox://ruoyaow.5ks2tw8m'>
                                        <Layer
                                            id="pollution_county_borders"
                                            type="line"
                                            source="pollution_county_data"
                                            source-layer="pollution_county"
                                            maxzoom={7}
                                            minzoom={5}
                                            paint = {border_paint}
                                            filter = {this.state.filter}
                                            beforeId = "waterway-label"
                                        />
                                        <Layer
                                            id="pollution_county"
                                            type="fill"
                                            source="pollution_county_data"
                                            source-layer="pollution_county"
                                            maxzoom={7}
                                            minzoom={5}
                                            paint = {pollution_county_choropleth_paint}
                                            beforeId = "pollution_county_borders"
                                        />				
                                    </Source>

                                    <Source id="pollution_state_data" type="vector" url='mapbox://ruoyaow.7grz25v1'>
                                        <Layer
                                            id="pollution_state_borders"
                                            type="line"
                                            source="pollution_state_data"
                                            source-layer="pollution_state"
                                            maxzoom={5}
                                            paint = {border_paint}
                                            filter = {this.state.filter}
                                            beforeId = "waterway-label"
                                        />
                                        <Layer
                                            id="pollution_state"
                                            type="fill"
                                            source="pollution_state_data"
                                            source-layer="pollution_state"
                                            maxzoom={5}
                                            paint = {pollution_state_choropleth_paint}
                                            beforeId = "pollution_state_borders"
                                        />				
                                    </Source>
                                    </div>
                                    }

                                </ReactMapGL>
                                </div>
                                {/* Legends of the maps */}
                                { this.state.selected_layer == '0' &&
                                <div id='accs-legend' className="legend">
                                    <h4>Accessibility Score</h4>
                                    <div><span style={{background: '#4b0000'}}></span>100,000,000</div>
                                    <div><span style={{background: '#7f0000'}}></span>90,000,000</div>
                                    <div><span style={{background: '#b30000'}}></span>80,000,000</div>
                                    <div><span style={{background: '#d7301f'}}></span>70,000,000</div>
                                    <div><span style={{background: '#ef6548'}}></span>60,000,000</div>
                                    <div><span style={{background: '#fc8d59'}}></span>50,000,000</div>
                                    <div><span style={{background: '#fdbb84'}}></span>40,000,000</div>
                                    <div><span style={{background: '#fdd49e'}}></span>30,000,000</div>
                                    <div><span style={{background: '#fee8c8'}}></span>20,000,000</div>
                                    <div><span style={{background: '#fff7ec'}}></span>10,000,000</div>
                                </div>
                                }
                                { this.state.selected_layer == '1' &&
                                <div id='LATCH-legend' className="legend">
                                    <h4>Number of trips</h4>
                                    <div><span style={{background: '#7f0000'}}></span>9+</div>
                                    <div><span style={{background: '#7f0000'}}></span>8.75</div>
                                    <div><span style={{background: '#b30000'}}></span>8.5</div>
                                    <div><span style={{background: '#d7301f'}}></span>8.25</div>
                                    <div><span style={{background: '#ef6548'}}></span>8</div>
                                    <div><span style={{background: '#fc8d59'}}></span>7.75</div>
                                    <div><span style={{background: '#fdbb84'}}></span>7.5</div>
                                    <div><span style={{background: '#fdd49e'}}></span>7.25</div>
                                    <div><span style={{background: '#fee8c8'}}></span>7</div>
                                    <div><span style={{background: '#fff7ec'}}></span>0</div>
                                    <div><span style={{background: '#000000'}}></span>Data Unavailable</div>
                                </div>
                                }
                                { this.state.selected_layer == '2' && this.state.viewport.zoom > 5 &&
                                <div id='FARS-legend-state' className="legend">
                                <h4>Fatals per 1000 people</h4>
                                <div><span style={{background: '#7f0000'}}></span>2</div>
                                <div><span style={{background: '#b30000'}}></span>1</div>
                                <div><span style={{background: '#d7301f'}}></span>0.75</div>
                                <div><span style={{background: '#ef6548'}}></span>0.5</div>
                                <div><span style={{background: '#fc8d59'}}></span>0.4</div>
                                <div><span style={{background: '#fdbb84'}}></span>0.3</div>
                                <div><span style={{background: '#fdd49e'}}></span>0.2</div>
                                <div><span style={{background: '#fee8c8'}}></span>0.1</div>
                                <div><span style={{background: '#fff7ec'}}></span>0</div>
                            </div>
                                }
                                { this.state.selected_layer == '2' && this.state.viewport.zoom <= 5 &&
                                <div id='FARS-legend-state' className="legend">
                                    <h4>Fatals per 1000 people</h4>
                                    <div><span style={{background: '#7f0000'}}></span>0.2</div>
                                    <div><span style={{background: '#b30000'}}></span>0.175</div>
                                    <div><span style={{background: '#d7301f'}}></span>0.15</div>
                                    <div><span style={{background: '#ef6548'}}></span>0.125</div>
                                    <div><span style={{background: '#fc8d59'}}></span>0.1</div>
                                    <div><span style={{background: '#fdbb84'}}></span>0.075</div>
                                    <div><span style={{background: '#fdd49e'}}></span>0.05</div>
                                    <div><span style={{background: '#fee8c8'}}></span>0.025</div>
                                    <div><span style={{background: '#fff7ec'}}></span>0</div>
                                </div>
                                }
                                { this.state.selected_layer == '3' &&
                                <div id='opportunity-legend' className="legend">
                                    <h4>Opportunity Score</h4>
                                    <div><span style={{background: '#4b0000'}}></span>1</div>
                                    <div><span style={{background: '#7f0000'}}></span>0.9</div>
                                    <div><span style={{background: '#b30000'}}></span>0.8</div>
                                    <div><span style={{background: '#d7301f'}}></span>0.7</div>
                                    <div><span style={{background: '#ef6548'}}></span>0.6</div>
                                    <div><span style={{background: '#fc8d59'}}></span>0.5</div>
                                    <div><span style={{background: '#fdbb84'}}></span>0.4</div>
                                    <div><span style={{background: '#fdd49e'}}></span>0.3</div>
                                    <div><span style={{background: '#fee8c8'}}></span>0.2</div>
                                    <div><span style={{background: '#fff7ec'}}></span>0.1</div>
                                    <div><span style={{background: '#000000'}}></span>0</div>
                                </div>
                                }
                                { this.state.selected_layer == '4' && this.state.viewport.zoom <= 5 &&
                                <div id='pollution-state-legend' className="legend">
                                    <h4>CO<sub>2</sub> emission (Kg/m<sup>2</sup>)</h4>
                                    <div><span style={{background: '#4b0000'}}></span>1.5</div>
                                    <div><span style={{background: '#7f0000'}}></span>1</div>
                                    <div><span style={{background: '#b30000'}}></span>0.75</div>
                                    <div><span style={{background: '#d7301f'}}></span>0.5</div>
                                    <div><span style={{background: '#ef6548'}}></span>0.25</div>
                                    <div><span style={{background: '#fc8d59'}}></span>0.2</div>
                                    <div><span style={{background: '#fdbb84'}}></span>0.15</div>
                                    <div><span style={{background: '#fdd49e'}}></span>0.1</div>
                                    <div><span style={{background: '#fee8c8'}}></span>0.05</div>
                                    <div><span style={{background: '#fff7ec'}}></span>0</div>
                                </div>
                                }
                                { this.state.selected_layer == '4' && this.state.viewport.zoom > 5 && this.state.viewport.zoom <=7 &&
                                <div id='pollution-county-legend' className="legend">
                                    <h4>CO<sub>2</sub> emission (Kg/m<sup>2</sup>)</h4>
                                    <div><span style={{background: '#4b0000'}}></span>7.5</div>
                                    <div><span style={{background: '#7f0000'}}></span>5</div>
                                    <div><span style={{background: '#b30000'}}></span>2.5</div>
                                    <div><span style={{background: '#d7301f'}}></span>1</div>
                                    <div><span style={{background: '#ef6548'}}></span>0.5</div>
                                    <div><span style={{background: '#fc8d59'}}></span>0.4</div>
                                    <div><span style={{background: '#fdbb84'}}></span>0.3</div>
                                    <div><span style={{background: '#fdd49e'}}></span>0.2</div>
                                    <div><span style={{background: '#fee8c8'}}></span>0.1</div>
                                    <div><span style={{background: '#fff7ec'}}></span>0</div>
                                </div>
                                }
                                { this.state.selected_layer == '4' && this.state.viewport.zoom > 7 &&
                                <div id='pollution-census-legend' className="legend">
                                    <h4>CO<sub>2</sub> emission (Kg/m<sup>2</sup>)</h4>
                                    <div><span style={{background: '#4b0000'}}></span>50</div>
                                    <div><span style={{background: '#7f0000'}}></span>20</div>
                                    <div><span style={{background: '#b30000'}}></span>10</div>
                                    <div><span style={{background: '#d7301f'}}></span>5</div>
                                    <div><span style={{background: '#ef6548'}}></span>2</div>
                                    <div><span style={{background: '#fc8d59'}}></span>1</div>
                                    <div><span style={{background: '#fdbb84'}}></span>0.5</div>
                                    <div><span style={{background: '#fdd49e'}}></span>0.2</div>
                                    <div><span style={{background: '#fee8c8'}}></span>0.1</div>
                                    <div><span style={{background: '#fff7ec'}}></span>0</div>
                                </div>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
    );
  }
}

export default Big_Map;